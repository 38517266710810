import { React } from "react";
import { useIsDesktop } from "../Hooks/WindowDimensions";

const FillWithMargin = ({ children, style = {} }) => {
  const isDesktop = useIsDesktop();
  return (
    <div className="fill" style={{ width: "100%", ...style }}>
      <div
        style={{
          margin: isDesktop ? "2rem" : "1rem",
          textAlign: "left",
          height: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FillWithMargin;

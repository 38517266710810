function resizeImage(image, maxWidth, maxHeight) {
  // Create a new Image object
  const newImage = new Image();

  // Set up a canvas element to draw the resized image onto
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set the maximum dimensions for the new image
  let newWidth = image.width;
  let newHeight = image.height;

  // Calculate the aspect ratio of the original image
  const aspectRatio = image.width / image.height;

  // Check if the image needs to be resized to fit within the maximum dimensions
  if (image.width > maxWidth || image.height > maxHeight) {
    if (aspectRatio > 1) {
      // Landscape orientation
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      // Portrait orientation
      newWidth = maxHeight * aspectRatio;
      newHeight = maxHeight;
    }
  }

  // Set the canvas size to match the new image size
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Draw the resized image onto the canvas
  context.drawImage(image, 0, 0, newWidth, newHeight);

  // Convert the canvas back to an Image object and return it
  newImage.src = canvas.toDataURL("image/jpeg");
  return newImage;
}

module.exports = { resizeImage };

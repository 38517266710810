import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardFooter } from "mdb-react-ui-kit";
import LinkButton from "../Components/LinkButton";
import Button from "../Components/Button";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";

Session.addAxiosInterceptors(axios);

function DraftGig({ gig, onDelete }) {
  const [enabled, setEnabled] = useState(true);

  async function deleteDraftGig() {
    // display a confirmation dialog
    if (!window.confirm("Are you sure you want to delete this draft?")) {
      return;
    }

    setEnabled(false);
    let success = false;
    axios
      .delete(getApiDomain() + "/deletedraftgig", {
        params: {
          gigId: gig.gigId,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setEnabled(false);
          success = true;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setEnabled(true);
        if (success) {
          onDelete(gig);
        }
      });
  }

  return (
    <MDBCard
      style={{
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <MDBCardBody>
        <MDBCardTitle style={{ fontWeight: "bold" }}>
          {gig.title ? gig.title : "No Title"}
        </MDBCardTitle>
        <MDBCardText>
          {gig.category ? gig.category : "No Category"} -{" "}
          {gig.subCategory ? gig.subCategory : "No Subcategory"}
        </MDBCardText>
        <MDBCardText>
          {gig.description
            ? `${gig.description.slice(0, 100) || gig.description}
            ${gig.description.length > 100 ? "..." : ""}`
            : "No Description"}
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter style={{ display: "flex", justifyContent: "space-between" }}>
        <FontAwesomeIcon
          icon={faTrashCan}
          style={{
            marginBottom: "auto",
            marginTop: "auto",
            color: enabled ? "orange" : "gray",
            fontSize: "1.5rem",
            pointerEvents: enabled ? "auto" : "none",
          }}
          onClick={deleteDraftGig}
        />
        <LinkButton to={"/post/" + gig.gigId}>EDIT</LinkButton>
      </MDBCardFooter>
    </MDBCard>
  );
}

export default DraftGig;

import React, { useState, useEfect } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import { MDBTextArea } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getApiDomain } from "../App";
import { useParams, useNavigate } from "react-router-dom";

function LeaveReview() {
  const [text, setText] = useState("");
  const [hoveredStar, setHoveredStar] = useState(5);
  const { orderId } = useParams();
  const [error, setError] = useState("");
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();

  async function submit() {
    try {
      setError("");
      setWaiting(true);
      const response = await axios.post(getApiDomain() + "/leaveReview", {
        orderId: orderId,
        review: text,
        rating: hoveredStar,
      });
      navigate("/orders");
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }
    } finally {
      setWaiting(false);
    }
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Leave A Review</h1>
      <p>Pleaes leave a review and rating for the seller.</p>
      {error !== "" && <p style={{ color: "red" }}>{error}</p>}
      <div style={{ display: "flex" }}>
        {[...Array(5)].map((e, i) => {
          return (
            <FontAwesomeIcon
              size="xl"
              style={{
                color: "orange",
                textAlign: "center",
                marginTop: "auto",
                marginBottom: "auto",
              }}
              icon={`fa-${i < hoveredStar ? "solid" : "regular"} fa-star`}
              onMouseEnter={(event) => {
                setHoveredStar(i + 1);
              }}
              onMouseLeave={(event) => {}}
              key={i}
            />
          );
        })}
        <p
          style={{
            marginLeft: ".5rem",
            fontSize: "1.5rem",
            textAlign: "center",
            verticalAlign: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {hoveredStar} star{hoveredStar !== 1 ? "s" : ""}
        </p>
      </div>
      <MDBTextArea
        style={{ marginTop: "1rem" }}
        label="Enter your review here"
        id="textAreaExample"
        rows={4}
        maxLength={2000}
        onChange={(val) => setText(val.target.value)}
      />
      <div style={{ marginTop: "0.5rem", color: "gray" }}>
        Character count: {text.length}. Minimum Characters: 50
      </div>
      <Button
        style={{ marginTop: "1rem" }}
        text={"SUBMIT"}
        enabled={text.length >= 50 && !waiting}
        onClick={submit}
      />
    </FillWithMargin>
  );
}

export default LeaveReview;

import React from "react";
import Accordion from "../Components/Accordion";
import LinkButton from "../Components/LinkButton";
import CenterSpinner from "../Components/CenterSpinner";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import AddonsTableWithoutCard from "../Cart/AddonsTableWithoutCard";

const OutgoingOrders = ({ orders, loading = false }) => {
  const isDesktop = useIsDesktop();
  const paidOrders = orders.filter((order) => order.status === "Paid");
  const ordersInProgres = orders.filter((order) => order.status === "In Progress");

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <h1 style={{ fontWeight: "bold" }}>My Orders</h1>
      {paidOrders.length > 0 && (
        <p style={{ backgroundColor: "yellow" }}>
          You have an order that is paid, but is missing requirements. Please complete the
          requirements to receive your delivery.
        </p>
      )}
      {ordersInProgres.length > 0 && <p>You have {ordersInProgres.length} orders in progress.</p>}
      {orders.length === 0 && <p>You don't have any orders yet.</p>}
      <div style={{ marginTop: "1rem" }}>
        {orders.map((order) => (
          <div style={{ marginTop: "1rem" }} key={order.id}>
            <Accordion
              key={order.id}
              expanded={order.status === "Paid"}
              title={
                <>
                  <p
                    style={{
                      fontWeight: "bold",
                      height: "100%",
                      verticalAlign: "center",
                      margin: "0px",
                    }}
                  >
                    {order.title}
                  </p>
                </>
              }
              content={
                <>
                  <div key={order.id} style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Order Id:</span> {order.id}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Seller:</span> {order.sellerUsername}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Status:</span> {order.status}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Created At:</span> {order.createdAt}
                      </p>
                    </div>
                    <div style={{ flex: 1 }}>
                      {order.deliveryDate !== null && (
                        <p>
                          <span style={{ fontWeight: "bold" }}>Delivery Date:</span>{" "}
                          {order.deliveryDate}
                        </p>
                      )}
                      {order.expectedDeliveryDate !== null && (
                        <p>
                          <span style={{ fontWeight: "bold" }}>Expected Delivery Date:</span>{" "}
                          {order.expectedDeliveryDate}
                        </p>
                      )}
                      <p>
                        <span style={{ fontWeight: "bold" }}>Amount:</span>
                        {"⚡"}
                        {order.totalAmount}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Package:</span> {order.gigPackage}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Revisions Allowed:</span>{" "}
                        {order.revisions}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Revisions Used:</span>{" "}
                        {order.revisionsUsed}
                      </p>
                    </div>
                  </div>
                  <div>
                    {order.addons && order.addons.length > 0 && (
                      <AddonsTableWithoutCard
                        addons={order.addons}
                        showSelect={false}
                        topMargin={false}
                      />
                    )}
                    {order.status === "Paid" && (
                      <LinkButton to={`/requirements/${order.id}`}>
                        COMPLETE REQUIREMENTS
                      </LinkButton>
                    )}
                    {order.status === "In Progress" && (
                      <div style={{ display: "flex" }}>
                        <LinkButton to={`/requirements/${order.id}`}>
                          SEE YOUR REQUIREMENTS
                        </LinkButton>
                        <LinkButton to={`/messages/${order.id}`}>MESSAGE SELLER</LinkButton>
                      </div>
                    )}
                    {order.status === "Delivered" && (
                      <>
                        <LinkButton to={`/delivery/${order.id}`}>SEE DELIVERED ORDER</LinkButton>
                        {order.reviewId === null && (
                          <LinkButton to={`/review/${order.id}`}>LEAVE REVIEW</LinkButton>
                        )}
                        {order.revisionsUsed < order.revisions && (
                          <>
                            <LinkButton to={`/revision/${order.id}`}>USE REVISION</LinkButton>
                          </>
                        )}
                        {order.revisionsUsed > 0 && (
                          <LinkButton to={`/revisions/${order.id}`}>SEE REVISIONS</LinkButton>
                        )}
                      </>
                    )}
                  </div>
                </>
              }
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default OutgoingOrders;

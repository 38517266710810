import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function CustomFeatureGrid({ initialData, onStateChange }) {
  const [formFields, setFormFields] = useState(initialData);
  const [centredModal, setCentredModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [removalIndex, setRemovalIndex] = useState(null);

  useEffect(() => {
    if (initialData) {
      setFormFields(initialData);
    }
  }, [initialData]);

  const toggleShow = () => setCentredModal(!centredModal);

  const handleFormChange = (event, row, col) => {
    let data = [...formFields];
    data[row].packages[col].description = event.target.value;
    setFormFields(data);
    onStateChange(data);
  };

  const handleTitleChange = (event, row) => {
    let data = [...formFields];
    data[row].title = event.target.value;
    setFormFields(data);
    onStateChange(data);
  };

  const addFields = () => {
    let object = {
      id: 0,
      title: "",
      packages: [
        { description: "", packageId: 1 },
        { description: "", packageId: 2 },
        { description: "", packageId: 3 },
      ],
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  return (
    <>
      <div className="mb-4">
        <MDBTable bordered style={{ marginBottom: "0px" }}>
          <MDBTableHead>
            <tr>
              <th scope="col">Feature</th>
              <th scope="col">Basic</th>
              <th scope="col">Standard</th>
              <th scope="col">Premium</th>
              <th scope="col"></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {formFields.map((form, row) => {
              return (
                <tr key={row}>
                  <td>
                    <input
                      name="feature"
                      onChange={(event) => handleTitleChange(event, row)} // TODO - add col
                      style={inputStyle}
                      value={form.title}
                      placeholder="Feature title"
                    ></input>
                  </td>
                  {form.packages.map((pack, col) => {
                    return (
                      <td style={{ padding: "0px" }} key={col + "-td"}>
                        <textarea
                          onChange={(event) => handleFormChange(event, row, col)}
                          style={inputStyle}
                          value={pack.description}
                          placeholder="Description (enter 'None' if not applicable)"
                          rows={4}
                        />
                      </td>
                    );
                  })}
                  <td>
                    {/* <Checkbox /> */}
                    <MDBIcon
                      far
                      icon="minus-square"
                      style={{ color: "orange" }}
                      onClick={() => {
                        setRemovalIndex(row);
                        setCentredModal(!centredModal);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </MDBTableBody>
        </MDBTable>
      </div>

      <div className="mb-4">
        <MDBCol style={{ paddingLeft: "0px" }} size="auto">
          <MDBBtn style={{ backgroundColor: "orange" }} onClick={addFields}>
            {/* <MDBIcon far icon="plus-square" /> */}
            Add Feature
          </MDBBtn>
        </MDBCol>
      </div>

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete Feature</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you sure you want to delete this feature?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={toggleShow}>
                Close
              </MDBBtn>
              <MDBBtn
                onClick={() => {
                  removeFields(removalIndex);
                  toggleShow();
                }}
                style={{ backgroundColor: "orange" }}
              >
                Confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal tabIndex="-1" show={saveModal} setShow={setSaveModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete Feature</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setSaveModal(!saveModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you sure you want to delete this feature?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                onClick={() => {
                  setSaveModal(!saveModal);
                }}
                style={{ backgroundColor: "orange" }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

const inputStyle = {
  border: "none",
  backgroundColor: "transparent",
  resize: "none",
  outline: "none",
  width: "100%",
};

import React, { useEffect, useState } from "react";
import { getApiDomain } from "../App";
import axios from "axios";
import FormattedNumber from "./FormattedNumber";

function BoostIcon({ gigId, boostAmount }) {
  const [boost, setBoost] = useState(0);

  useEffect(() => {
    if (gigId) {
      axios
        .get(getApiDomain() + "/currentBoost", {
          params: { gigId: gigId },
        })
        .then((response) => {
          if (response.status === 200) {
            setBoost(response.data.boostAmount);
          }
        })
        .catch((error) => {});
    }
  }, [gigId]);

  return (
    <>
      <FormattedNumber number={boostAmount ? boostAmount : boost} /> sats boosted
    </>
  );
}

export default BoostIcon;

import React from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import AddonsTableWithoutCard from "./AddonsTableWithoutCard";

const AddonsTable = ({ addons, onSelect, showSelect = true }) => {
  return (
    <MDBCard style={{ marginTop: "2rem", overflowX: "auto" }}>
      <MDBCardBody>
        <AddonsTableWithoutCard
          addons={addons}
          onSelect={onSelect}
          showSelect={showSelect}
        />
      </MDBCardBody>
    </MDBCard>
  );
};

export default AddonsTable;

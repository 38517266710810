import React from "react";

export default function AboutThisGig({ jobDescription }) {
  return (
    <>
      <p className="fs-5" style={{ fontWeight: "bold" }}>
        About this Gig
      </p>
      <p>{jobDescription}</p>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Button from "../Components/Button";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

Session.addAxiosInterceptors(axios);

function BoostPostButton({ gigId, setError }) {
  const [basicModal, setBasicModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const toggleOpen = () => setBasicModal(!basicModal);

  function handleClick() {
    toggleOpen();
  }

  function handleConfirm() {
    if (amount <= 0) {
      setErrorMessage("Please enter a valid amount");
      return;
    } else {
      setErrorMessage("");
      navigate(`/gig/${gigId}/boost/${amount}`);
    }
  }

  return (
    <>
      <Button text={"Add Boost"} onClick={handleClick} />
      <MDBModal tabIndex="-1" show={basicModal} setShow={setBasicModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Boost Gig</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>How many sats would you like to boost your gig by?</p>
              {errorMessage !== "" && <p style={{ color: "red" }}>{errorMessage}</p>}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                ⚡{" "}
                <MDBInput
                  id="typeNumber"
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                &nbsp;sats
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={toggleOpen}>
                Cancel
              </MDBBtn>
              <MDBBtn style={{ backgroundColor: "orange" }} onClick={handleConfirm}>
                Confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default BoostPostButton;

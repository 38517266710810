import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// images is an array of files
export default function GigImageCarousel({ images }) {
  return (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      <Carousel
        renderThumbs={(children) => {
          return children.map((child, index) => {
            return (
              <div key={index} style={{ width: "100%" }}>
                <img src={child.props.src} alt="" style={{ width: "100%", height: "auto" }} />
              </div>
            );
          });
        }}
      >
        {images.map((image, index) => {
          return (
            <img
              key={index}
              src={image}
              alt=""
              style={{
                height: "30vh",
                objectFit: "cover",
              }}
            />
          );
        })}
      </Carousel>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import PopularGigs from "./PopularGigs";
import ScrollingButtons from "./ExploreByCategory";
import axios from "axios";
import { getApiDomain } from "../App";
import ExploreGrid from "./ExploreGrid";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import Dropdown from "../Components/Dropdown";

export default function Explore() {
  const [categories, setCategories] = useState([]);
  const [gigIds, setGigIds] = useState([]);
  const isDesktop = useIsDesktop();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sort, setSort] = useState();

  useEffect(() => {
    axios
      .get(getApiDomain() + "/getGigCategories")
      .then((res) => {
        let cats = [
          ...res.data.categories,
          {
            id: -1,
            category: "Select All",
            count: res.data.categories.filter((item) => item.count > 0).length,
          },
        ];
        setCategories(cats);
      })
      .catch((err) => {
        console.log("there was an error: " + err); // TODO catch error
      });
  }, []);

  async function getGigIdsForCategory(category) {
    try {
      let response = await axios.get(getApiDomain() + "/getGigIdsForCategory", {
        params: {
          category: category,
        },
      });
      if (response.status === 200) {
        // use response.data to add gig ids to gigIds, without erasing existing gig ids
        setGigIds((curr) => [
          ...curr,
          ...response.data.map((item) => {
            return {
              gigId: item.gigId,
              categoryId: item.categoryId,
              price: item.price,
              totalBoost: item.totalBoost,
            };
          }),
        ]);
      }
    } catch (error) {
      console.log("there was an error: " + error); // TODO catch error
    }
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Explore The Marketplace</h1>
      {/* <p style={{}}>Most popular Gigs</p>
      <PopularGigs />
      <p>Explore by Category</p> */}
      {/* TODO display the number of gigs availalable for each category */}
      <div style={{ marginTop: isDesktop ? "2rem" : "1rem" }}>
        <ScrollingButtons
          buttons={categories}
          onSelect={(value) => {
            getGigIdsForCategory(value);
          }}
          onDeSelect={(value) => {
            // remove gig ids from gigIds
            setGigIds((curr) => {
              return curr.filter((item) => item.categoryId.toString() !== value.id.toString());
            });
          }}
        />

        <div
          style={{
            marginTop: isDesktop ? "2rem" : "1rem",
          }}
        >
          {gigIds.length === 0 ? (
            <p>
              Please select a category - {categories.filter((item) => item.count > 0).length - 1}{" "}
              gigs available.
            </p>
          ) : (
            <ExploreGrid
              gigIds={gigIds
                .sort((a, b) => {
                  if (sort === "priceLowToHigh") {
                    return a.price - b.price;
                  } else if (sort === "priceHighToLow") {
                    return b.price - a.price;
                  } else if (sort === "totalBoostLowToHigh") {
                    return a.totalBoost - b.totalBoost;
                  } else if (sort === "totalBoostHighToLow") {
                    return b.totalBoost - a.totalBoost;
                  } else {
                    return 0;
                  }
                })
                .map((item) => item.gigId)}
              showSort
              showFilter
              setSortValue={(value) => {
                setSort(value);
              }}
            />
          )}
        </div>
      </div>
    </FillWithMargin>
  );
}

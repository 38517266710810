import React from "react";
import { Link } from "react-router-dom";
import { MDBCardText, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProfileAndRating({ job, profile }) {
  return (
    <>
      <MDBRow>
        <MDBCol size="auto">
          <img
            src={profile.profilePic}
            style={{
              height: "2rem",
              width: "2rem",
              borderRadius: "1rem",
              margin: 0,
              objectFit: "cover",
            }}
            alt=""
          ></img>
        </MDBCol>

        <MDBCol size="auto" style={{ padding: 0 }}>
          <Link to={`/user/${job.postedBy}`} style={{ color: "black" }}>
            {job.postedBy}
          </Link>
        </MDBCol>
        <MDBCol size="auto">
          {[...Array(job.sellerRating)].map((e, i) => {
            return <FontAwesomeIcon icon="fa-solid fa-star" style={{ color: "orange" }} key={i} />;
          })}
        </MDBCol>
        <MDBCol style={{ padding: 0 }}>
          <MDBCardText
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            {job.sellerRating} stars
          </MDBCardText>
        </MDBCol>
      </MDBRow>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { getApiDomain } from "../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import CenterSpinner from "../Components/CenterSpinner";
import { MDBTextArea } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";

const requiredCharacters = 15;

const CompleteRequirements = () => {
  const { orderId } = useParams();
  const [requirements, setRequirements] = useState([]);
  const [completedRequirements, setCompletedRequirements] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function getRequirements() {
      try {
        const response = await axios.get(getApiDomain() + "/orders/requirements", {
          params: {
            orderId: orderId,
          },
        });
        if (response.data.requirements !== undefined) {
          setRequirements(response.data.requirements);
          // Initialize answers array with empty objects for each requirement
          const initialAnswers = response.data.requirements.map((requirement) => ({
            id: requirement.id,
            answer: "",
          }));
          setAnswers(initialAnswers);
        }

        if (response.data.completedRequirements !== undefined) {
          setCompletedRequirements(response.data.completedRequirements);
        }
      } catch (error) {
        console.log("There was an error:", error);
        setWarningMessage(error.response.data.error);
      }
    }
    getRequirements();
  }, [orderId]);

  const handleAnswerChange = (event, id) => {
    const { value } = event.target;
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) => (answer.id === id ? { ...answer, answer: value } : answer))
    );
  };

  useEffect(() => {
    // Function to check requirements character count and update button status
    const checkRequirements = () => {
      const allRequirementsComplete = requirements.every((requirement) => {
        const answer = answers.find((answer) => answer.id === requirement.id);
        return answer?.answer.length >= requiredCharacters;
      });
      setIsButtonDisabled(!allRequirementsComplete);
    };

    // Check requirements whenever the answers state changes
    checkRequirements();
  }, [answers, requirements]);

  const handleSubmit = () => {
    // Perform any necessary actions with the answers
    async function saveAnswers() {
      setIsButtonDisabled(true); // Disable the button after submission

      try {
        await axios.post(getApiDomain() + "/saveOrderRequirements", {
          orderId: orderId,
          answers: answers,
        });
        setErrorMessage("");
        navigate("/orders");
      } catch (error) {
        if (error.response.status === 500) {
          if (error.response.data.error) {
            setErrorMessage(error.response.data.error);
          }
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      } finally {
        setIsButtonDisabled(false);
      }
    }

    saveAnswers();
  };

  if (warningMessage !== "") {
    return (
      <div className="fill" style={{ width: "100%" }}>
        <div
          style={{
            margin: "2rem",
            textAlign: "left",
          }}
        >
          {warningMessage}
        </div>
      </div>
    );
  }

  if (requirements.length === 0 && completedRequirements.length === 0) {
    return <CenterSpinner />;
  }

  return (
    <div className="fill" style={{ width: "100%" }}>
      <div
        style={{
          margin: "2rem",
          textAlign: "left",
        }}
      >
        {requirements.length > 0 && (
          <>
            <h1 style={{ fontWeight: "bold" }}>Complete Requirements</h1>
            <p>
              Thanks for your order! Please complete the following requirements so the seller can
              get started on your order.
            </p>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div>
              {requirements.map((requirement) => {
                const answer = answers.find((answer) => answer.id === requirement.id);
                const characterCount = answer ? answer.answer.length : 0;

                return (
                  <div key={requirement.id} style={{ marginTop: "2rem" }}>
                    <p>{requirement.requirement}</p>
                    <MDBTextArea
                      type="text"
                      name={requirement.id}
                      value={answer?.answer}
                      onChange={(event) => handleAnswerChange(event, requirement.id)}
                      placeholder="Answer"
                    />
                    <div style={{ marginTop: "0.5rem", color: "gray" }}>
                      Character count: {characterCount}. Minimum Characters: {requiredCharacters}
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ marginTop: "2rem" }}>
              <Button
                text={"COMPLETE REQUIREMENTS"}
                enabled={!isButtonDisabled}
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
        {completedRequirements.length > 0 && (
          <>
            <h1 style={{ fontWeight: "bold" }}>Completed Requirements</h1>
            <p>Here is a summary of the requirements for order {orderId}.</p>
            <div>
              {completedRequirements.map((requirement) => {
                return (
                  <div key={requirement.id} style={{ marginTop: "2rem" }}>
                    <p style={{ fontWeight: "bold" }}>{requirement.requirement}</p>
                    <p>{requirement.answer}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompleteRequirements;

import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import GigTable from "./GigTable";
import AddonsTable from "./AddonsTable";
import ProfileAndRating from "../Profile/ProfileAndRating";
import FormattedNumber from "../Components/FormattedNumber";
import CenterSpinner from "../Components/CenterSpinner";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import FillWithMargin from "../Components/FillWithMargin";

const Cart = () => {
  const { gigId, gigPackage } = useParams();
  const [totalCost, setTotalCost] = useState(0);
  const [addonsSelected, setAddonsSelected] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("PAY NOW USING LIGHTNING");
  const [enabled, setEnabled] = useState(true);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [gig, setGig] = useState({
    title: "",
    postedBy: "",
    sellerRating: 0,
    basicPrice: 0,
    basicDescription: "",
    standardPrice: 0,
    standardDescription: "",
    premiumPrice: 0,
    premiumDescription: "",
    reviews: [
      // {
      //   username: "",
      //   stars: 0,
      //   reviewText: "",
      //   dateLeft: "",
      // },
    ],
    jobDescription: "",
    images: [],
    features: [
      // {
      //   title: "",
      //   description: "",
      //   packageId: 0,
      //   parentId: 0,
      // }
    ],
    addons: [
      // {
      //   title: "",
      //   additionalCost: 0,
      //   additionalTime: 0,
      // }
    ],
  });
  const [profile, setProfile] = useState({
    from: "",
    memberSince: "",
    avgResponseTime: 0,
    lastDelivery: 0,
    biography: "",
    sellerStatement: "",
    profilePic: "",
  });

  // warn the user before they refresh the page. As of now we are storing the invoiceid in state. it gets lost when refreshed.
  useEffect(() => {
    const confirmRefresh = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", confirmRefresh);

    return () => {
      window.removeEventListener("beforeunload", confirmRefresh);
    };
  }, []);

  useEffect(() => {
    async function fetchGig() {
      try {
        const response = await axios.get(getApiDomain() + "/gig", {
          params: { gigId: gigId },
        });
        setGig(response.data.gig);
        setProfile(response.data.profile);

        if (gigPackage === "basic") {
          setTotalCost(response.data.gig.basicPrice);
        } else if (gigPackage === "standard") {
          setTotalCost(response.data.gig.standardPrice);
        } else if (gigPackage === "premium") {
          setTotalCost(response.data.gig.premiumPrice);
        }
      } catch (error) {
        console.log("There was an error:", error);
      }
    }
    fetchGig();

    async function fetchUserName() {
      try {
        const response = await axios.get(getApiDomain() + "/getUserName");
        setUserName(response.data.userName);
      } catch (error) {
        console.log("There was an error:", error);
      }
    }
    fetchUserName();
  }, [gigId, gigPackage]);

  const handlePaymentButtonClick = async () => {
    navigate(`/cart/${gigId}/${gigPackage}/pay`, {
      state: { addonsSelected: addonsSelected, gig: gig },
    });
  };

  const handleAddonSelect = (checked, addon) => {
    // Handle the event when a select button is clicked
    if (checked) {
      setTotalCost(totalCost + addon.additionalCost);
    } else {
      setTotalCost(totalCost - addon.additionalCost);
    }

    // Update the list of selected addons
    if (checked) {
      setAddonsSelected([...addonsSelected, addon]);
    } else {
      setAddonsSelected(addonsSelected.filter((a) => a !== addon));
    }
  };

  if (gig.title === "" || userName === "") {
    return <CenterSpinner />;
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Checkout</h1>
      {gig.postedBy === userName ? (
        <p>You cannot order your own gig.</p>
      ) : (
        <>
          {gig && (
            <div>
              <h3 style={{ marginTop: "2rem" }}>{gig.title}</h3>
              <div style={{ marginTop: "2rem" }}>
                <ProfileAndRating job={gig} profile={profile} />
              </div>
              <div style={{ marginTop: "2rem" }}>
                <GigTable gig={gig} gigPackage={gigPackage} />
              </div>
              {gig.addons.length > 0 && (
                <AddonsTable addons={gig.addons} onSelect={handleAddonSelect} />
              )}
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "1rem" }}>
                  Total:
                  <FormattedNumber number={totalCost} />
                </span>

                <MDBBtn
                  style={{ backgroundColor: "orange" }}
                  onClick={handlePaymentButtonClick}
                  disabled={!enabled}
                >
                  {status}
                </MDBBtn>
              </div>
              <div style={{ marginTop: "2rem", color: "red" }}>
                {errorMessage !== "" && <>{errorMessage}</>}
              </div>
            </div>
          )}
        </>
      )}
    </FillWithMargin>
  );
};

export default Cart;

import React, { useEffect, useState, useCallback } from "react";
import { MDBRow, MDBCol, MDBInput, MDBTextArea, MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import ImageFileUpload from "../Components/ImageFileUpload";
import { resizeImage } from "../Utilities/Images";
import { useNavigate } from "react-router-dom";
import LinkButton from "../Components/LinkButton";
Session.addAxiosInterceptors(axios);

export default function ProfileSettings() {
  const navigate = useNavigate();

  const [generalError, setGeneralError] = useState("");
  const [bio, setBio] = useState("");
  const [description, setDescription] = useState("");
  const [from, setFrom] = useState("");
  const [languages, setLanguages] = useState([""]);
  const [skills, setSkills] = useState([""]);
  const [files, setFiles] = useState([]);
  const [authorized, setAuthorized] = useState(true);
  const [userName, setUserName] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [languagesCount, setLanguagesCount] = useState(1);
  const [skillsCount, setSkillsCount] = useState(1);

  useEffect(() => {
    async function getData() {
      let sessionUserId = await Session.getUserId();

      await axios
        .get(getApiDomain() + "/usersettings", {
          params: { userId: sessionUserId },
        })
        .then((response) => {
          if (response.status !== 200) {
            setAuthorized(false);
          }

          setBio(response.data.data.bio);
          setDescription(response.data.data.description);
          setFrom(response.data.data.from);
          setLanguages(response.data.data.languages);
          setSkills(response.data.data.skills);
          setUserName(response.data.data.userName);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setAuthorized(false);
          }
        });
    }

    getData().catch(console.error);
  }, []);

  useEffect(() => {
    setLanguagesCount(languages.length);
  }, [languages]);

  useEffect(() => {
    setSkillsCount(skills.length);
  }, [skills]);

  async function saveFormData() {
    setGeneralError("");
    setSaveButtonDisabled(true);

    const dataUrls = [];
    for (let i = 0; i < files.length; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = files[i].width;
      canvas.height = files[i].height;
      const context = canvas.getContext("2d");
      context.drawImage(files[i], 0, 0);
      const dataUrl = canvas.toDataURL("image/jpeg");
      dataUrls.push(dataUrl);
    }

    try {
      const response = await axios.post(getApiDomain() + "/saveProfileData", {
        bio: bio,
        description: description,
        from: from,
        languages: languages,
        skills: skills,
        dataUrls: dataUrls,
      });

      if (response.status === 200) {
        setGeneralError("");
      }
      navigate("/user/" + userName);
    } catch (error) {
      console.log(error);
      if (
        error &&
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.generalError !== undefined
      ) {
        setGeneralError(error.response.data.generalError);
      } else {
        setGeneralError("Something went wrong. Please try again.");
      }
    } finally {
      setSaveButtonDisabled(false);
    }
  }

  function addLanguage() {
    if (languagesCount < 9) {
      setLanguagesCount((curr) => curr + 1);
      setLanguages((curr) => [...curr, ""]);
    }
  }

  function addSkill() {
    if (skillsCount < 9) {
      setSkillsCount((curr) => curr + 1);
      setSkills((curr) => [...curr, ""]);
    }
  }

  function removeLanguage() {
    setLanguagesCount((curr) => {
      if (curr > 1) {
        setLanguages((curr) => [...curr.slice(0, -1)]);
        return curr - 1;
      } else {
        return curr;
      }
    });
  }

  function removeSkill() {
    setSkillsCount((curr) => {
      if (curr > 1) {
        setSkills((curr) => [...curr.slice(0, -1)]);
        return curr - 1;
      } else {
        return curr;
      }
    });
  }

  function setLanguage(index, language) {
    const newLanguages = languages.map((l, i) => {
      if (i === index) {
        return language;
      } else {
        return l;
      }
    });
    setLanguages(newLanguages); // update state
  }

  function setSkill(index, skill) {
    const newSkills = skills.map((s, i) => {
      if (i === index) {
        return skill;
      } else {
        return s;
      }
    });
    setSkills(newSkills); // update state
  }

  function readFileAsImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  const onFilesChange = useCallback(async (newFileArray) => {
    // resize the images
    let resizedImages = [];
    let totalSize = 0;
    for (let i = 0; i < newFileArray.length; i++) {
      const file = newFileArray[i];
      totalSize += file.size;
      const image = await readFileAsImage(file);
      const resizedImage = await resizeImage(image, 800, 800);
      resizedImages.push(resizedImage);
    }

    if (totalSize > 10000000) {
      //10 million bytes aka 10MB
      setGeneralError("Total size of images cannot exceed 10MB.");
    } else {
      setGeneralError("");
      setFiles(resizedImages);
    }
  }, []);

  if (!authorized) {
    return (
      <>
        <p>You are not authorized to view this page.</p>
      </>
    );
  }

  return (
    <>
      <MDBRow className="mb-4">
        {generalError !== "" && (
          <MDBRow className="mb-4" style={{ color: "red" }}>
            {generalError}
          </MDBRow>
        )}

        <MDBRow className="mb-4">Upload Profile Picture</MDBRow>
        <ImageFileUpload
          onFilesChange={onFilesChange}
          setErrorMessage={setGeneralError}
          maxFiles={1}
        />

        <MDBInput
          wrapperClass="mb-4"
          name="bio"
          label="Bio (1 sentence)"
          value={bio}
          onInput={(event) => setBio(event.target.value)}
        />

        <MDBTextArea
          wrapperClass="mb-4"
          name="description"
          label="Description (3 - 4 sentences)"
          value={description}
          onInput={(event) => setDescription(event.target.value)}
        />

        <MDBInput
          wrapperClass="mb-4"
          label="From"
          name="from"
          value={from}
          onInput={(event) => setFrom(event.target.value)}
        />

        {Array.from({ length: languagesCount }, (_, k) => (
          <MDBInput
            key={k}
            wrapperClass="mb-4"
            label={`Language ${k + 1}`}
            name={`language${k}`}
            value={languages.length >= k + 1 ? languages[k] : ""}
            onInput={(event) => setLanguage(k, event.target.value)}
          />
        ))}

        <MDBCol style={{ paddingLeft: "0px" }} size="auto">
          <MDBBtn className="mb-4" style={{ backgroundColor: "orange" }} onClick={addLanguage}>
            Add Language
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn className="mb-4" style={{ backgroundColor: "orange" }} onClick={removeLanguage}>
            Remove Language
          </MDBBtn>
        </MDBCol>
        {Array.from({ length: skillsCount }, (_, k) => (
          <MDBInput
            key={k}
            wrapperClass="mb-4"
            label={`Skill ${k + 1}`}
            name={`skill${k}`}
            value={skills.length >= k + 1 ? skills[k] : ""}
            onInput={(event) => setSkill(k, event.target.value)}
          />
        ))}
        <MDBCol style={{ paddingLeft: "0px" }} size="auto">
          <MDBBtn className="mb-4" style={{ backgroundColor: "orange" }} onClick={addSkill}>
            Add Skill
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn className="mb-4" style={{ backgroundColor: "orange" }} onClick={removeSkill}>
            Remove Skill
          </MDBBtn>
        </MDBCol>

        {/* <MDBCheckbox
          wrapperClass="d-flex justify-content-center mb-4"
          id="form6Example8"
          label="Create an account?"
          defaultChecked
          style={{
            borderColor: "orange",
            backgroundColor: checked ? "orange" : "",
          }}
          onChange={checkChanged}
        /> */}

        <MDBBtn
          style={{ backgroundColor: "orange" }}
          onClick={saveFormData}
          disabled={saveButtonDisabled}
        >
          Save Changes
        </MDBBtn>
      </MDBRow>
    </>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";
import { redirectToAuth } from "supertokens-auth-react";
import axios, { toFormData } from "axios";
import { getApiDomain } from "../App";
import UserContext from "../Context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import bflogo2 from "../images/logo_transparent_cropped.png";
import BFLink from "../Components/BFLink";

function Navigation() {
  const [expanded, setExpanded] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    profilePic: "",
  });
  const { setUsername } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(false);

  let session = useSessionContext();
  let { doesSessionExist } = session;

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.innerWidth <= 1000; // Adjust the breakpoint as needed
      setIsMobile(!isMobileDevice);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    }
  }, [isMobile]);

  useEffect(() => {
    async function getUser() {
      try {
        let response = await axios.get(getApiDomain() + "/sessioninfo");
        if (response.data.user != null) {
          setUserData(response.data.user);
          setUsername(response.data.user.userName);
        }
      } catch (error) {
        console.log("The user is currently not logged in or no session exists");
        setUsername("");
      }
    }
    if (session && session.userId !== undefined && session.userId !== "") {
      getUser();
    }
  }, [session?.userId, setUsername, session]);

  return (
    <div
      style={{
        backgroundColor: "rgb(38, 38, 38)",
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Link to="/">
          <img src={bflogo2} alt="" loading="lazy" style={{ maxWidth: "200px", margin: "1rem" }} />
        </Link>
        {!isMobile && (
          <div
            onClick={() => setExpanded((curr) => !curr)}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "2rem",
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              style={{
                color: "gray",
                fontSize: "1.5rem",
              }}
            />
          </div>
        )}
        <div
          style={{
            display: isMobile ? "flex" : "none",
            flexDirection: isMobile ? "row" : "column",
          }}
        >
          <Links
            setExpanded={setExpanded}
            doesSessionExist={doesSessionExist}
            userData={userData}
            isMobile={isMobile}
            expanded={expanded}
          />
        </div>
      </div>

      <div
        style={{
          display: expanded ? "flex" : "none",
          flexDirection: "row",
        }}
      >
        <Links
          setExpanded={setExpanded}
          doesSessionExist={doesSessionExist}
          userData={userData}
          isMobile={isMobile}
          expanded={expanded}
        />
      </div>
    </div>
  );
}

function Links({ setExpanded, doesSessionExist, userData, isMobile, expanded }) {
  const navigate = useNavigate();

  async function onSignUp() {
    redirectToAuth({ show: "signup" });
    setExpanded(false);
  }

  async function onSignIn() {
    redirectToAuth({ show: "signin" });
    setExpanded(false);
  }

  const handleLogout = async () => {
    setExpanded(false);
    await signOut();
    navigate("/auth");
  };

  return (
    <div
      style={{
        paddingLeft: expanded ? "2rem" : "0rem",
        display: "flex",
        flexDirection: expanded ? "column" : "row",
        marginTop: "auto",
        marginBottom: expanded ? "2rem" : "auto",
      }}
    >
      {doesSessionExist ? (
        <>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              flexDirection: expanded ? "column" : "row",
            }}
          >
            <NavBarLink
              to="/explore"
              onClick={() => {
                setExpanded(false);
              }}
              expanded={expanded}
            >
              Explore
            </NavBarLink>
            <NavBarLink
              to="/search"
              onClick={() => {
                setExpanded(false);
              }}
              expanded={expanded}
            >
              Search
            </NavBarLink>
          </div>
          <MDBDropdown>
            <MDBDropdownToggle style={{ marginRight: "1rem" }}>Account</MDBDropdownToggle>
            <MDBDropdownMenu>
              <DropDownItem to={`/user/${userData.userName}`} setExpanded={setExpanded}>
                Profile
              </DropDownItem>
              <DropDownItem to={"/post/select-gig"} setExpanded={setExpanded}>
                Create & Edit Gigs
              </DropDownItem>
              <DropDownItem to={"/orders"} setExpanded={setExpanded}>
                My Orders
              </DropDownItem>
              <DropDownItem to={"/settings"} setExpanded={setExpanded}>
                Edit Profile
              </DropDownItem>
              <DropDownItem to={`/subscribe/${userData.unsubscribeGuid}`} setExpanded={setExpanded}>
                Notifications
              </DropDownItem>
              <DropDownItem to={"/auth"} setExpanded={setExpanded} onClick={handleLogout}>
                Sign Out
              </DropDownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div style={{ marginRight: "1rem" }}></div>
        </>
      ) : (
        <>
          <NavBarLink
            to="/explore"
            onClick={() => {
              setExpanded(false);
            }}
            expanded={expanded}
          >
            Explore
          </NavBarLink>
          <NavBarLink
            to="/search"
            onClick={() => {
              setExpanded(false);
            }}
            expanded={expanded}
          >
            Search
          </NavBarLink>
          <NavBarLink onClick={onSignUp} expanded={expanded}>
            Sign Up
          </NavBarLink>
          <NavBarLink onClick={onSignIn} expanded={expanded}>
            Sign In
          </NavBarLink>
          <div style={{ marginRight: "1rem" }}></div>
        </>
      )}
    </div>
  );
}

function DropDownItem({ to, children, setExpanded, onClick }) {
  return (
    <MDBDropdownItem
      link
      onClick={() => {
        setExpanded(false);
      }}
    >
      <BFLink to={to} onClick={onClick}>
        {children}
      </BFLink>
    </MDBDropdownItem>
  );
}

function NavBarLink({ to, children, onClick, expanded }) {
  return (
    <Link
      to={to}
      style={{
        color: "gray",
        transition: "color 0.3s ease-in-out",
        marginRight: "1rem",
        whiteSpace: "nowrap",
        marginBottom: expanded ? "1rem" : "0rem",
      }}
      // Add hover style
      onMouseEnter={(e) => (e.target.style.color = "orange")}
      onMouseLeave={(e) => (e.target.style.color = "gray")}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

export default Navigation;

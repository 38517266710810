import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
} from "mdb-react-ui-kit";
import FillWithMargin from "../Components/FillWithMargin";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CenterSpinner from "../Components/CenterSpinner";

export default function DescriptionAndFaq() {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [formFields, setFormFields] = useState({
    description: "",
    faq: [{ question: "", answer: "" }],
  });

  // error message state
  const [gigDescriptionError, setGigDescriptionError] = useState("");
  const [faqError, setFaqError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [loading, setLoading] = useState(true);
  const [centredModal, setCentredModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [removalIndex, setRemovalIndex] = useState(null);

  const toggleShow = () => setCentredModal(!centredModal);
  const { gigId } = useParams();
  const navigate = useNavigate();

  const handleFaqChange = (event, index) => {
    let data = { ...formFields };
    data.faq[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleDescriptionChange = (event) => {
    let data = { ...formFields };
    data.description = event.target.value;
    setFormFields(data);
  };

  const addFields = () => {
    let object = {
      question: "",
      answer: "",
    };

    // update faq array
    let data = { ...formFields };
    data.faq.push(object);
    setFormFields(data);
  };

  const removeFields = (index) => {
    let data = { ...formFields };
    data.faq.splice(index, 1);
    setFormFields(data);
  };

  useEffect(() => {
    async function getFormData() {
      await axios
        .get(getApiDomain() + "/getGigDescriptionAndFaq", {
          params: { gigId: gigId },
        })
        .then((response) => {
          if (response.status === 200) {
            setFormFields(response.data);
          }
        })
        .catch((error) => {
          setGeneralError("An error occurred.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getFormData();
  }, []);

  async function saveFormData() {
    setSaveButtonDisabled(true);
    axios
      .post(getApiDomain() + "/saveGigDescriptionAndFaq", { ...formFields, gigId: gigId })
      .then((response) => {
        if (response.status === 200) {
          setGeneralError("");
          setGigDescriptionError("");
          setFaqError("");
          navigate(`/post/${gigId}/requirements`);
        }
      })
      .catch((error) => {
        if (error.response) {
          setGeneralError(error.response.data.generalError);
          setGigDescriptionError(error.response.data.gigDescriptionError);
          setFaqError(error.response.data.faqError);
        }
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  }

  return (
    <>
      <FillWithMargin>
        <p>3 of 6</p>
        <h1 style={{ fontWeight: "bold" }}>Add A Description</h1>
        {generalError !== "" && (
          <div className="mb-4" style={{ color: "red" }}>
            {generalError}
          </div>
        )}
        <div className="mb-4" style={{ paddingRight: "0px" }}>
          Briefly Describe your gig. Max length is 2000 characters.
        </div>
        {loading ? (
          <CenterSpinner />
        ) : (
          <>
            <div className="mb-4" style={{ paddingRight: "0px" }}>
              <MDBTextArea
                label="Gig Description"
                id="textAreaExample"
                rows={4}
                value={formFields.description}
                onChange={handleDescriptionChange}
                maxLength={2000}
              />
            </div>
            {gigDescriptionError !== "" && (
              <div className="mb-4" style={{ color: "red" }}>
                {gigDescriptionError}
              </div>
            )}
            <div className="mb-4" style={{ paddingRight: "0px" }}>
              Potential buyers might have some common questions about your gig. Add some questions
              and answers here to give your buyers a feel for what you're offering.
            </div>
            <div className="mb-4">
              <MDBTable bordered style={{ marginBottom: "0px" }}>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                    <th scope="col"></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {formFields.faq.map((faqItem, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input
                            name="question"
                            onChange={(event) => handleFaqChange(event, index)}
                            style={inputStyle}
                            value={faqItem.question}
                            placeholder="How quickly can you get started?"
                          ></input>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <textarea
                            name="answer"
                            onChange={(event) => handleFaqChange(event, index)}
                            style={inputStyle}
                            value={faqItem.answer}
                            placeholder="Answer"
                            rows={4}
                          />
                        </td>

                        <td>
                          {/* <Checkbox /> */}
                          <MDBIcon
                            far
                            icon="minus-square"
                            style={{ color: "orange" }}
                            onClick={() => {
                              setRemovalIndex(index);
                              setCentredModal(!centredModal);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>

            {faqError !== "" && (
              <div className="mb-4" style={{ color: "red" }}>
                {faqError}
              </div>
            )}

            <div className="mb-4">
              <MDBCol style={{ paddingLeft: "0px" }} size="auto">
                <MDBBtn style={{ backgroundColor: "orange" }} onClick={addFields}>
                  {/* <MDBIcon far icon="plus-square" /> */}
                  Add question
                </MDBBtn>
              </MDBCol>
            </div>

            <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
              <MDBModalDialog>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Delete question</MDBModalTitle>
                    <MDBBtn className="btn-close" color="none" onClick={toggleShow}></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <p>Are you sure you want to delete this question?</p>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn color="light" onClick={toggleShow}>
                      Close
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => {
                        removeFields(removalIndex);
                        toggleShow();
                      }}
                      style={{ backgroundColor: "orange" }}
                    >
                      Confirm
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex="-1" show={saveModal} setShow={setSaveModal}>
              <MDBModalDialog>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Delete question</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={() => setSaveModal(!saveModal)}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <p>Are you sure you want to delete this question?</p>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      onClick={() => {
                        setSaveModal(!saveModal);
                      }}
                      style={{ backgroundColor: "orange" }}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
            <div className="mb-4" style={{ paddingRight: "0px" }}>
              <MDBBtn
                type="submit"
                block
                style={{ backgroundColor: "orange" }}
                onClick={saveFormData}
                disabled={saveButtonDisabled}
              >
                Save & Continue
              </MDBBtn>
            </div>
          </>
        )}
      </FillWithMargin>
    </>
  );
}

const inputStyle = {
  border: "none",
  backgroundColor: "transparent",
  resize: "none",
  outline: "none",
  width: "100%",
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import Accordion from "../Components/Accordion";
import LinkButton from "../Components/LinkButton";
import AddonsTable from "../Cart/AddonsTable";
import AddonsTableWithoutCard from "../Cart/AddonsTableWithoutCard";
import { Link } from "react-router-dom";

const IncomingOrders = ({ incomingOrders, loading }) => {
  const ordersInProgress = incomingOrders.filter((order) => order.status === "In Progress");

  if (loading) {
    return <CenterSpinner />;
  }

  /* Example incomingOrder 
    {
      status: order.status,
      id: order.order_id,
      title: order.title,
      gigId: order.gig_id,
      totalAmount: order.total_amount,
      deliveryDate: order.delivery_date,
      packageName: order.package_name,
    }
  */

  return (
    <>
      <h1 style={{ fontWeight: "bold" }}>Awaiting Fulfillment</h1>
      {ordersInProgress.length > 0 && (
        <>
          <p>
            You have {ordersInProgress.length} orders awaiting fulfillment. Please complete these
            orders to receive payment .
          </p>
        </>
      )}
      {incomingOrders.length === 0 && <p>You don't have any orders yet.</p>}
      <div style={{ marginTop: "1rem" }}>
        {incomingOrders.map((order) => (
          <div style={{ marginTop: "1rem" }} key={order.id}>
            <Accordion
              key={order.id}
              expanded={order.status === "In Progress"}
              title={
                <>
                  <p
                    style={{
                      fontWeight: "bold",
                      height: "100%",
                      verticalAlign: "center",
                      margin: "0px",
                    }}
                  >
                    {order.title}
                  </p>
                </>
              }
              content={
                <>
                  <div key={order.id} style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        flex: "50%",
                        padding: "0.5rem",
                        boxSizing: "border-box",
                      }}
                    >
                      <p>
                        <span style={{ fontWeight: "bold" }}>Order Id:</span> {order.id}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Status:</span> {order.status}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Package:</span> {order.packageName}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Completed Revisions:</span>{" "}
                        {order.completedRevisions}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Pending Revisions:</span>{" "}
                        {order.incompleteRevisions}
                      </p>
                    </div>
                    <div
                      style={{
                        flex: "50%",
                        padding: "0.5rem",
                        boxSizing: "border-box",
                      }}
                    >
                      <p>
                        <span style={{ fontWeight: "bold" }}>Total:</span> ⚡{order.totalAmount}
                      </p>
                      {order.deliveryDate !== null && (
                        <p>
                          <span style={{ fontWeight: "bold" }}>Delivery Date:</span>{" "}
                          {order.deliveryDate}
                        </p>
                      )}
                      {order.expectedDeliveryDate !== null && (
                        <p>
                          <span style={{ fontWeight: "bold" }}>Expected Delivery Date:</span>{" "}
                          {order.expectedDeliveryDate}
                        </p>
                      )}
                      <p>
                        <span style={{ fontWeight: "bold" }}>Ordered On:</span> {order.createdAt}
                      </p>
                    </div>
                    <div style={{ width: "100%" }}>
                      {order.addons && order.addons.length > 0 && (
                        <AddonsTableWithoutCard
                          addons={order.addons}
                          showSelect={false}
                          topMargin={false}
                        />
                      )}
                      {order.status === "In Progress" && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                          }}
                        >
                          <LinkButton to={`/requirements/${order.id}`}>
                            SEE BUYERS REQUIREMENTS
                          </LinkButton>
                          <LinkButton to={`/messages/${order.id}`}>MESSAGE BUYER</LinkButton>
                          <LinkButton to={`/deliver/${order.id}`}>DELIVER ORDER</LinkButton>
                        </div>
                      )}
                      {(order.completedRevisions > 0 || order.incompleteRevisions > 0) && (
                        <LinkButton to={`/revisions/${order.id}`}>SEE REVISIONS</LinkButton>
                      )}
                    </div>
                  </div>
                </>
              }
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default IncomingOrders;

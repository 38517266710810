import React from "react";

const FormattedNumber = ({ number }) => {
  const formattedNumber = number
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace("$", "")
    .split(".")[0];

  return <span>⚡{formattedNumber}</span>;
};

export default FormattedNumber;

import React, { useState, useEffect } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import OutgoingOrders from "./OutgoingOrders";
import IncomingOrders from "./IncomingOrders";
import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane } from "mdb-react-ui-kit";
import axios from "axios";
import { getApiDomain } from "../App";

const Orders = () => {
  const [basicActive, setBasicActive] = useState("tab1");

  // outgoing orders
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);

  // incoming orders
  const [incomingOrders, setIncomingOrders] = useState([]);
  const [incomingOrdersLoading, setIncomingOrdersLoading] = useState(true);

  useEffect(() => {
    async function getOutgoingOrders() {
      try {
        const response = await axios.get(getApiDomain() + "/getOutgoingOrders");
        setOrders(response.data.orders);
        setOrdersLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      }
    }
    getOutgoingOrders();
  }, []);

  useEffect(() => {
    async function getIncomingOrders() {
      try {
        const response = await axios.get(getApiDomain() + "/orders/incoming");
        setIncomingOrders(response.data.incomingOrders);
        setIncomingOrdersLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      }
    }
    getIncomingOrders();
  }, []);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <FillWithMargin>
      <>
        <MDBTabs className="mb-3">
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick("tab1")} active={basicActive === "tab1"}>
              Outgoing Orders
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick("tab2")} active={basicActive === "tab2"}>
              Incoming Orders
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <OutgoingOrders orders={orders} loading={ordersLoading} />
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab2"}>
            <IncomingOrders incomingOrders={incomingOrders} loading={incomingOrdersLoading} />
          </MDBTabsPane>
        </MDBTabsContent>
      </>
    </FillWithMargin>
  );
};

export default Orders;

import React, { useEffect, useRef, useState } from "react";

import "./Dropdown.css";

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const Dropdown = ({
  placeHolder, // greyed out text that is displayed when no items are selected
  options, // default options [{label: "", value: "", defaultSelected: true/false}]
  isMulti, // allow multiple items selected
  isSearchable, // set to true for search bar at top of options
  onChange, // update outside state with the selected items
  style,
}) => {
  const [optionState, setOptionState] = useState(options);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (options !== optionState) {
      setOptionState(options);
      // clear selected option if none is selected by default
      let anyOptionSelected = false;
      for (let i = 0; i < options.length; i++) {
        if (options[i].defaultSelected === true) {
          anyOptionSelected = true;
        }
      }

      if (!anyOptionSelected) {
        setSelectedValue(isMulti ? [] : null);
      }
    }
  }, [options, optionState, isMulti]);

  // use effect to populate default value as selected
  useEffect(() => {
    // for some reason this has to be in a use effect rather than in the useState() call
    if (isMulti) {
      if (selectedValue.length === 0) {
        let values = getDefaultSelectedValues(options);
        if (values.length > 0) {
          setSelectedValue(values);
        }
      }
    } else {
      if (selectedValue === null) {
        let values = getDefaultSelectedValues(options);
        if (values !== null) {
          setSelectedValue(values);
        }
      }
    }

    function getDefaultSelectedValues(ops) {
      let selectedOptions = [];
      for (let i = 0; i < ops.length; i++) {
        if (ops[i].defaultSelected === true) {
          selectedOptions.push(ops[i]);
        }
      }

      if (isMulti) {
        return selectedOptions; // return array of options
      } else {
        if (selectedOptions.length > 0) {
          return selectedOptions[0]; // return a single option
        }
      }

      return null; // should never be hit
    }
    // onChange(values);
  }, [isMulti, selectedValue, options]); // for some reason this cannot be a zero dependenct useEffect

  // useEffect(() => {
  //   let currentOptionExists = true;
  //   if (isMulti) {
  //     if (isMulti && selectedValue.length > 0) {
  //       for (let i = 0; i < options.length; i++) {
  //         if (options[i].value !== selectedValue.value) {
  //           currentOptionExists = false;
  //         }
  //       }
  //     }
  //   }
  //   if (!isMulti && selectedValue !== null) {
  //     for (let i = 0; i < options.length; i++) {
  //       if (options[i].value !== selectedValue) {
  //         currentOptionExists = false;
  //       }
  //     }
  //   }

  //   if (!currentOptionExists) {
  //     setSelectedValue(isMulti ? [] : null);
  //   }
  // });

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue) {
      return placeHolder;
    }

    if (selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span onClick={(e) => onTagRemove(e, option)} className="dropdown-tag-close">
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue.label;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option) => {
    if (isMulti) {
      if (selectedValue.length === 0) {
        return false;
      }
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="dropdown-container" style={{ ...style }}>
      <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="dropdown-menu2">
          {isSearchable && (
            <div className="search-box">
              <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
          )}
          {getOptions().map((option) => (
            <div
              onClick={() => onItemClick(option)}
              key={option.value}
              className={`dropdown-item ${isSelected(option) && "selected"}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import React from "react";
import EditButton from "./EditButton";
import BoostPostButton from "./BoostPostButton";
import BoostIcon from "../Components/BoostIcon";

export default function TitleEditAndBoostButton({
  showEditButton,
  gig,
  gigId,
  setEditErrorMessage,
  isDesktop,
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isDesktop ? "row" : "column",
          marginBottom: "1rem",
        }}
      >
        <p
          className="fs-3"
          style={{
            fontWeight: "bold",
            marginRight: isDesktop ? "" : "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {gig.title}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: isDesktop ? "" : "auto",
            marginTop: isDesktop ? "" : "1rem",
          }}
        >
          {showEditButton && (
            <span>
              <EditButton
                gigId={gigId}
                setError={setEditErrorMessage}
                style={{ marginRight: "1rem" }}
              />
              <BoostPostButton gigId={gigId} setError={setEditErrorMessage} />
            </span>
          )}

          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <BoostIcon boostAmount={gig.boostAmount} />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useCallback, useEffect } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import GenericFileUpload from "../Components/FileUpload";
import { MDBTextArea } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import axios from "axios";
import { getApiDomain } from "../App";
import Checkbox from "../Components/Checkbox";
import { useParams, useNavigate } from "react-router-dom";
import CenterSpinner from "../Components/CenterSpinner";
import RevisionCard from "./RevisionCard";

const Deliver = () => {
  const [generalError, setGeneralError] = useState("");
  const [files, setFiles] = useState([]);
  const [fileData, setFileData] = useState({});
  const [description, setDescription] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [fileUploadError, setFileUploadError] = useState("");
  const [loading, setLoading] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const [selectedRevisions, setRevisionsSelected] = useState([]);

  const navigate = useNavigate();
  const { orderId } = useParams();

  // Add a useEffect hook to update the isButtonDisabled state when the description changes.
  useEffect(() => {
    setIsButtonDisabled(description.length < 150);
  }, [description]);

  useEffect(() => {
    async function getRevisions() {
      setLoading(true);
      try {
        const response = await axios.get(getApiDomain() + "/getRevisions", {
          params: { orderId: orderId },
        });
        // filter response.data.revisions to only include revisions that are incomplete
        const filteredRevisions = response.data.revisions.filter(
          (revision) => revision.status === "Incomplete"
        );
        setRevisions(filteredRevisions);
      } catch (error) {
        console.log("There was an error:", error);
      } finally {
        setLoading(false);
      }
    }

    getRevisions();
  }, []);

  const handleSubmit = () => {
    async function deliverGig() {
      setGeneralError("");
      setFileUploadError("");
      setIsButtonDisabled(true);

      try {
        const response = await axios.post(getApiDomain() + "/deliverOrder", {
          gigDescription: description,
          files: files,
          fileData: fileData,
          orderId: orderId,
          revisions: selectedRevisions,
        });

        if (response.status === 200) {
          setGeneralError("");
          // redirect the user to the order page using react router dom
          navigate("/orders");
        }
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.generalError) {
          setGeneralError(error.response.data.generalError);
        } else if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 413
        ) {
          setGeneralError(
            "Please Reduce the file size and try again. In genral, 40mb (give or take) is the maximum file size allowed."
          );
        } else {
          setGeneralError("Something went wrong. Please try again.");
        }
      } finally {
        setIsButtonDisabled(false);
      }
    }

    if (showFileUpload && files.length === 0) {
      setFileUploadError("Please upload a file.");
      return;
    }

    if (revisions.length > 0 && selectedRevisions.length === 0) {
      setGeneralError("Please select at least one revision.");
      return;
    }

    deliverGig();
  };

  const handleFilesChange = useCallback((files) => {
    setFiles(files);
  }, []);

  return (
    <>
      <FillWithMargin>
        <h1 style={{ fontWeight: "bold" }}>Deliver Order</h1>
        {loading ? (
          <>
            <CenterSpinner />
          </>
        ) : (
          <>
            <Checkbox
              onChange={() => setShowFileUpload((curr) => !curr)}
              checked={showFileUpload}
              label="This delivery contains a file"
            />
            {showFileUpload && (
              <>
                <p>Please upload your delivery for this order.</p>
                {fileUploadError && <div style={{ color: "red" }}>{fileUploadError}</div>}

                <GenericFileUpload
                  onFilesChange={handleFilesChange}
                  setFormData={setFileData}
                  setErrorMessage={setFileUploadError}
                  allowedFiles={[]}
                  accept="*/*"
                />
              </>
            )}
            <p>Please describe your delivery for the buyer.</p>
            <MDBTextArea
              wrapperClass="mb-4"
              name="Describe your gig"
              label="Describe your gig"
              value={description}
              onInput={(event) => {
                setDescription(event.target.value);
              }}
            />
            <div style={{ color: "gray" }}>
              Character count: {description.length}. Minimum Characters: 150
            </div>
            {revisions.length > 0 && (
              <>
                <div style={{ marginTop: "2rem" }}>
                  <p>{selectedRevisions.length} Revisions selected</p>
                  {revisions.map((revision, index) => (
                    <div key={index}>
                      <RevisionCard key={index} revision={revision}>
                        <Checkbox
                          label={"Complete Revision"}
                          onChange={(val) => {
                            if (val) {
                              // add the revision.id to the list of revisions
                              setRevisionsSelected((curr) => [...curr, revision.id]);
                            } else {
                              // remove the revision.id from the list of revisions
                              setRevisionsSelected((curr) => curr.filter((r) => r !== revision.id));
                            }
                          }}
                        />
                      </RevisionCard>
                    </div>
                  ))}
                </div>
              </>
            )}
            {generalError && <div style={{ color: "red" }}>{generalError}</div>}

            <Button text={"DELIVER ORDER"} enabled={!isButtonDisabled} onClick={handleSubmit} />
          </>
        )}
      </FillWithMargin>
    </>
  );
};

export default Deliver;

import React from "react";
import { MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import LinkButton from "../Components/LinkButton";

export default function ComparePackages({ job, jobId }) {
  return (
    <>
      <p className="fs-5" style={{ fontWeight: "bold" }}>
        Compare Packages
      </p>
      <MDBCard>
        <MDBCardBody>
          <MDBTable hover>
            <MDBTableHead>
              <tr style={{ verticalAlign: "top", textAlign: "start" }}>
                <th scope="col" style={{ verticalAlign: "bottom" }}>
                  <p style={{ fontWeight: "bold" }}>Feature</p>
                </th>
                <th scope="col">
                  <p style={{ fontSize: "1.2rem" }}>⚡{job.basicPrice.toLocaleString()}</p>
                  <p style={{ fontWeight: "bold" }}>Basic</p>
                  <p>{job.basicDescription}</p>
                </th>
                <th scope="col">
                  <p style={{ fontSize: "1.2rem" }}>⚡{job.standardPrice.toLocaleString()}</p>
                  <p style={{ fontWeight: "bold" }}>Standard</p>
                  <p>{job.standardDescription}</p>
                </th>
                <th scope="col">
                  <p style={{ fontSize: "1.2rem" }}>⚡{job.premiumPrice.toLocaleString()}</p>
                  <p style={{ fontWeight: "bold" }}>Premium</p>
                  <p>{job.premiumDescription}</p>
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <th scope="row">Delivery Time</th>
                <td>{job.basicDeliveryTime} days</td>
                <td>{job.standardDeliveryTime} days</td>
                <td>{job.premiumDeliveryTime} days</td>
              </tr>
              <tr>
                <th scope="row">Revisions</th>
                <td>{job.basicRevisions}</td>
                <td>{job.standardRevisions}</td>
                <td>{job.premiumRevisions}</td>
              </tr>
              {job.features.map((feature, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{feature.title}</th>
                    <td>{feature.basicDescription}</td>
                    <td>{feature.standardDescription}</td>
                    <td>{feature.premiumDescription}</td>
                  </tr>
                );
              })}
              <tr>
                <th scope="row"></th>
                <td>
                  <LinkButton to={`/cart/${jobId}/basic`}>SELECT</LinkButton>
                </td>
                <td>
                  <LinkButton to={`/cart/${jobId}/standard`}>SELECT</LinkButton>
                </td>
                <td>
                  <LinkButton to={`/cart/${jobId}/premium`}>SELECT</LinkButton>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </MDBCardBody>
      </MDBCard>
    </>
  );
}

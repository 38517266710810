import React, { useState, useEffect } from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import Dropdown from "../Components/Dropdown";
// import MultiSelect from "../Components/MultiSelect";
import FillWithMargin from "../Components/FillWithMargin";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CenterSpinner from "../Components/CenterSpinner";
Session.addAxiosInterceptors(axios);

export default function CreateGig() {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [gigTitle, setGigTitle] = useState("");
  const [category, setCategory] = useState({
    value: -1,
    label: "",
    defaultSelected: false,
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [updateSubCategories, setUpdateSubCategories] = useState(false);
  const [formData, setFormData] = useState({
    category: { value: -1, label: "" },
    subcategory: { value: -1, label: "" },
    // keywords: [],
    gigTitle: "",
  });
  const [formDataLoading, setFormDataLoading] = useState(true);
  const [verifyLoading, setVerifyLoading] = useState(true);
  const [ValidationMessage, setValidationMessage] = useState("");

  const { gigId } = useParams();
  const navigate = useNavigate();

  async function saveFormData() {
    setSaveButtonDisabled(true);
    axios
      .post(getApiDomain() + "/saveBasicInformation", {
        gigTitle: formData.gigTitle,
        category: formData.category,
        subCategory: formData.subcategory,
        gigId: gigId,
      })
      .then((response) => {
        if (response.status === 200) {
          setValidationMessage("");
          navigate(`/post/${gigId}/add-features`);
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        setValidationMessage(message);
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  }

  useEffect(() => {
    async function verifyLNURLPayAddress() {
      await axios
        .get(getApiDomain() + "/verifyLNURLPayAddressExists")
        .then((response) => {
          if (response.data.result === false) {
            navigate(`/post/${gigId}/verify-lnurl`);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setVerifyLoading(false);
        });
    }

    verifyLNURLPayAddress();
  }, [navigate]);

  useEffect(() => {
    async function getFormData() {
      await axios
        .get(getApiDomain() + "/getBasicInformation", {
          params: { gigId: gigId },
        })
        .then((response) => {
          setGigTitle(response.data.gigTitle);
          setFormData((f) => ({ ...f, gigTitle: response.data.gigTitle }));

          setCategoryOptions(response.data.categoryOptions);
          for (let i = 0; i < response.data.categoryOptions.length; i++) {
            if (response.data.categoryOptions[i].defaultSelected) {
              let category = response.data.categoryOptions[i];
              setFormData((f) => ({
                ...f,
                category: { value: category.value, label: category.label },
              }));
            }
          }

          setSubCategoryOptions(response.data.subCategoryOptions);
          for (let i = 0; i < response.data.subCategoryOptions.length; i++) {
            if (response.data.subCategoryOptions[i].defaultSelected) {
              let subGategory = response.data.subCategoryOptions[i].value;
              setFormData((f) => ({
                ...f,
                subcategory: { value: subGategory, label: subGategory },
              }));
            }
          }
          setFormData((f) => ({
            ...f,
          }));
        })
        .catch((err) => {
          setValidationMessage("An error occurred.");
        })
        .finally(() => {
          setFormDataLoading(false);
        });
    }

    // TODO AARON implement error handling and notify developers
    getFormData().catch((err) => {
      console.log("there was an error: " + err);
    });
  }, []);

  useEffect(() => {
    // if the category changes, update the subcategory options
    async function getSubCategories() {
      if (category !== null) {
        let response = await axios.get(getApiDomain() + "/getSubCategories", {
          params: { categoryId: category.value },
        });

        setSubCategoryOptions(response.data.subCategories);
      }
    }
    if (updateSubCategories) {
      getSubCategories().catch((err) => {
        console.log("there was an error: " + err);
      });
    }
  }, [category, updateSubCategories]);

  useEffect(() => {
    setUpdateSubCategories(true);
  }, [category]);

  function updateCategory(value) {
    setCategory(value);
    setFormData({ ...formData, category: value });
  }

  function updateSubCategory(value) {
    setFormData({ ...formData, subcategory: value });
  }

  return (
    <>
      <FillWithMargin>
        <p>1 of 6</p>
        <h1 style={{ fontWeight: "bold" }}>Post a Gig</h1>
        <p>Get started by adding important details about your gig.</p>
        {formDataLoading || verifyLoading ? (
          <CenterSpinner />
        ) : (
          <>
            <div className="mb-4">
              <div className="mb-4" style={{ paddingRight: "0px" }}>
                <MDBInput
                  name="gigTitle"
                  label="Gig Title"
                  value={gigTitle}
                  onInput={(event) => {
                    setFormData({ ...formData, gigTitle: event.target.value });
                    setGigTitle(event.target.value);
                  }}
                />
              </div>

              <div className="mb-4" style={{ paddingRight: "0px", display: "flex", width: "100%" }}>
                <Dropdown
                  isSearchable={true}
                  placeHolder="Category"
                  options={categoryOptions}
                  onChange={updateCategory}
                  isMulti={false}
                  style={{ width: "50%", marginRight: ".5rem" }}
                />
                <Dropdown
                  placeHolder="Sub Category"
                  options={subCategoryOptions}
                  onChange={updateSubCategory}
                  isMulti={false}
                  style={{ width: "50%", marginLeft: ".5rem" }}
                />
              </div>
              <div
                className={ValidationMessage !== "" ? "mb-4" : ""}
                style={{ paddingRight: "0px", color: "red" }}
              >
                {ValidationMessage}
              </div>
              <div className="mb-4" style={{ paddingRight: "0px" }}>
                <MDBBtn
                  type="submit"
                  block
                  style={{ backgroundColor: "orange" }}
                  onClick={saveFormData}
                  disabled={saveButtonDisabled}
                >
                  Save & Continue
                </MDBBtn>
              </div>
            </div>
          </>
        )}
      </FillWithMargin>
    </>
  );
}

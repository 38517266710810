import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import GigCardById from "../GigCard/GigCardById";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import Dropdown from "../Components/Dropdown";

function ExploreGrid({ gigIds, showSort, showFilter, setSortValue }) {
  const isDesktop = useIsDesktop();
  const [placeHolder, setPlaceHolder] = useState("Sort By");
  const [searchText, setSearchText] = useState("");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        {showFilter && (
          <div>
            <form className="d-flex input-group w-auto">
              <input
                style={{ backgroundColor: "white" }}
                type="search"
                className="form-control"
                placeholder="Filter Results"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </form>
          </div>
        )}
        {showSort && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1rem",
              marginLeft: isDesktop ? "1rem" : "0rem",
              marginTop: isDesktop ? "0rem" : "1rem",
              width: isDesktop ? "auto" : "100%",
            }}
          >
            <Dropdown
              isSearchable={false}
              placeHolder={placeHolder}
              options={[
                {
                  value: "priceLowToHigh",
                  label: "Price Low To High",
                },
                {
                  value: "priceHighToLow",
                  label: "Price High To Low",
                },
                {
                  value: "totalBoostLowToHigh",
                  label: "Sats Boosted Low To High",
                },
                {
                  value: "totalBoostHighToLow",
                  label: "Sats Boosted High To Low",
                },
              ]}
              onChange={(value) => {
                setSortValue(value.value);
                setPlaceHolder(value.label);
              }}
              isMulti={false}
              style={{ marginLeft: "auto", width: "100%", minWidth: "12rem" }}
            />
          </div>
        )}
      </div>

      <MDBRow className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {gigIds.map((id) => {
          return <GigCardById gigId={id} serachText={searchText} key={id} />;
        })}
      </MDBRow>
    </div>
  );
}

export default ExploreGrid;

import React, { useEffect, useState } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import LinkButton from "../Components/LinkButton";
import CenterSpinner from "../Components/CenterSpinner";
import DraftGig from "./DraftGig";
Session.addAxiosInterceptors(axios);

function SelectGigToEdit() {
  const [loading, setLoading] = useState(true);
  const [gigs, setGigs] = useState([]);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    async function getDraftGigs() {
      axios
        .get(getApiDomain() + "/getdraftgigs")
        .then((response) => {
          if (response.status === 200) {
            setGigs(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    getDraftGigs();
  }, []);

  function handleClick() {
    async function createDraftGig() {
      setDisabled(true);
      axios
        .post(getApiDomain() + "/createdraftgig")
        .then((response) => {
          if (response.status === 200) {
            let gigId = response.data.gigId;
            navigate("/post/" + gigId);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDisabled(false);
        });
    }

    // if confirmed, create draft gig
    createDraftGig();
  }

  function onDelete(gig) {
    setGigs(gigs.filter((g) => g.gigId !== gig.gigId));
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Select Gig To Edit</h1>
      <p>Select a gig to get started editing.</p>
      <MDBRow className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {loading ? (
          <CenterSpinner />
        ) : (
          <>
            {gigs.map((gig) => (
              <MDBCol key={gig.gigId}>
                <DraftGig gig={gig} onDelete={onDelete} />
              </MDBCol>
            ))}
            <MDBCol>
              <h5 style={{ fontWeight: "bold" }}>Create New Gig</h5>
              <Button text={"CONTINUE"} onClick={handleClick} enabled={!disabled}></Button>
            </MDBCol>
          </>
        )}
      </MDBRow>
    </FillWithMargin>
  );
}

export default SelectGigToEdit;

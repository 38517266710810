import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import Button from "./Button";

export default function FileUpload({
  onFilesChange,
  setFormData,
  setErrorMessage,
  allowedFiles = ["image/jpeg", "image/png", "image/gif"],
  accept = "image/*",
  maxFiles = 10,
}) {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const inputRef = useRef();
  const [hovering, setHovering] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData(uploadedFiles);
  }, [uploadedFiles, setFormData]);

  useEffect(() => {
    onFilesChange(files);
  }, [files, onFilesChange, setUploadedFiles]);

  async function readFileAsDataURL(file) {
    setLoading(true);
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    setLoading(false);
    return result_base64;
  }

  async function handleNewFile(e) {
    let fileDoesNotAlreadyExist = true;
    for (const file of files) {
      if (file.name === e.target.files[0].name) {
        fileDoesNotAlreadyExist = false;
      }
    }

    if (fileDoesNotAlreadyExist) {
      setFiles(e, "a");

      let file = e.target.files[0];
      let data = await readFileAsDataURL(file);

      let newCurr = [
        ...uploadedFiles,
        {
          name: file.name,
          type: file.type,
          size: file.size,
          data: data,
        },
      ];
      setUploadedFiles(newCurr);
      setErrorMessage("");
    } else {
      setErrorMessage("File already exists");
    }
  }

  function handleDragDrop(event) {
    handleDragDropEvent(event);
    const droppedFiles = event.dataTransfer.files;
    const allowedTypes = allowedFiles;
    const isValidFileType = [...droppedFiles].every((file) =>
      allowedTypes.includes(file.type)
    );
    const isWithinMaxFiles =
      droppedFiles.length <= maxFiles &&
      files.length + droppedFiles.length <= maxFiles;

    let fileDoesNotAlreadyExist = true;
    for (const file of droppedFiles) {
      if (files.find((f) => f.name === file.name)) {
        fileDoesNotAlreadyExist = false;
      }
    }
    if (isValidFileType && isWithinMaxFiles && fileDoesNotAlreadyExist) {
      handleNewFile(event);
      setErrorMessage("");
    } else {
      // alert("Only image files are allowed");
      if (!isValidFileType) {
        setErrorMessage("Invalid file type");
      }

      if (!isWithinMaxFiles) {
        setErrorMessage(`Only ${maxFiles} files are allowed`);
      }

      if (!fileDoesNotAlreadyExist) {
        setErrorMessage("File already exists");
      }
    }
  }

  function removeFileFromDataSet(name) {
    removeFile(name);

    // remove from uploadedFiles the first file with this filename
    setUploadedFiles((prev) => {
      return prev.filter((file) => file.name !== name);
    });
  }

  return (
    <div style={{ padding: "0px", margin: "0px" }}>
      <div style={{ marginTop: "0px" }}>
        {/* Provide a drop zone and an alternative button inside it to upload files. */}
        <div
          style={{
            backgroundColor: "#f5f5f5",
            marginTop: "0px",
            textAlign: "center",
            padding: "2rem",
            borderRadius: "5px",
            borderWidth: "2px",
            borderColor: hovering ? "orange" : "#f5f5f5",
            borderStyle: "dashed",
          }}
          onDragEnter={handleDragDropEvent}
          onMouseEnter={() => {
            setHovering(true);
          }}
          onMouseLeave={() => {
            setHovering(false);
          }}
          onDragOver={handleDragDropEvent}
          onDrop={handleDragDrop}
        >
          <p>Drag and drop files here</p>

          <MDBBtn
            onClick={() => inputRef.current.click()}
            style={{ backgroundColor: "orange" }}
          >
            Or select files to upload
          </MDBBtn>

          {/* Hide the crappy looking default HTML input */}
          <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: "none" }}
            accept={accept}
            onChange={(e) => {
              if (
                e.target.files.length > maxFiles ||
                files.length + e.target.files.length > maxFiles
              ) {
                setErrorMessage(`Only ${maxFiles} files are allowed`);
                return;
              }
              handleNewFile(e);
              inputRef.current.value = null;
            }}
          />
        </div>

        {/* Display the files to be uploaded */}
        <div style={{ marginTop: "2rem" }}>
          {fileNames.length > 0 && (
            <>
              <MDBTable bordered style={{ marginBottom: "0px" }}>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Filename</th>
                    <th scope="col"></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {fileNames.map((name, index) => {
                    return (
                      <tr key={index}>
                        <td>{name}</td>
                        <td>
                          <MDBIcon
                            far
                            icon="minus-square"
                            style={{ color: "orange" }}
                            onClick={() => removeFileFromDataSet(name)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {loading && (
                    <>
                      <tr key={1000000000000000}>
                        <td>{"Loading..."}</td>
                        <td></td>
                      </tr>
                    </>
                  )}
                </MDBTableBody>
              </MDBTable>
            </>
          )}

          {files.length > 0 && <p>Total Size: {totalSize}</p>}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import LinkButton from "../Components/LinkButton";
import PackageTable from "./PackageTable";

export default function ComparePackagesMobile({ job, jobId }) {
  return (
    <>
      <PackageTable job={job} jobId={jobId} gigPackage={"basic"} />
      <PackageTable job={job} jobId={jobId} gigPackage={"standard"} />
      <PackageTable job={job} jobId={jobId} gigPackage={"premium"} />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

export default function StandardFeatureGrid({ initialData, onStateChange }) {
  const [featureData, setFeatureData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setFeatureData(initialData);
    }
  }, [initialData]);

  function handleStateChange(newState) {
    setFeatureData(newState);
    onStateChange(newState);
  }

  return (
    <>
      <div className="mb-4">
        <MDBTable bordered style={{ marginBottom: "0px" }}>
          <MDBTableHead>
            <tr>
              <th scope="col">Package</th>
              <th scope="col">Basic</th>
              <th scope="col">Standard</th>
              <th scope="col">Premium</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td>Describe this package</td>
              <td style={{ padding: "0px" }}>
                <textarea
                  placeholder="Enter basic package description here"
                  rows={4}
                  style={inputStyle}
                  value={featureData.basic.description}
                  onChange={(event) => {
                    handleStateChange({
                      ...featureData,
                      basic: {
                        ...featureData.basic,
                        description: event.target.value.slice(0, 255),
                      },
                    });
                  }}
                />
              </td>
              <td style={{ padding: "0px" }}>
                <textarea
                  placeholder="Enter standard package description here"
                  rows={4}
                  style={inputStyle}
                  value={featureData.standard.description}
                  onChange={(event) => {
                    handleStateChange({
                      ...featureData,
                      standard: {
                        ...featureData.standard,
                        description: event.target.value.slice(0, 255),
                      },
                    });
                  }}
                />
              </td>
              <td style={{ padding: "0px" }}>
                <textarea
                  placeholder="Enter premium package description here"
                  rows={4}
                  style={inputStyle}
                  value={featureData.premium.description}
                  onChange={(event) =>
                    handleStateChange({
                      ...featureData,
                      premium: {
                        ...featureData.premium,
                        description: event.target.value.slice(0, 255),
                      },
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Delivery time</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Days</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    min={1}
                    placeholder="3"
                    value={featureData.basic.deliveryTime}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        basic: {
                          ...featureData.basic,
                          deliveryTime: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Days</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="4"
                    min={1}
                    value={featureData.standard.deliveryTime}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        standard: {
                          ...featureData.standard,
                          deliveryTime: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Days</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="5"
                    min={1}
                    value={featureData.premium.deliveryTime}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        premium: {
                          ...featureData.premium,
                          deliveryTime: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Revisions</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Revisions</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="1"
                    min={1}
                    value={featureData.basic.revisions}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        basic: {
                          ...featureData.basic,
                          revisions: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Revisions</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="2"
                    min={1}
                    value={featureData.standard.revisions}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        standard: {
                          ...featureData.standard,
                          revisions: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Revisions</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="3"
                    min={1}
                    value={featureData.premium.revisions}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        premium: {
                          ...featureData.premium,
                          revisions: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", backgroundColor: "#FED8B1 " }}>Price</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Sats</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="1"
                    min={1}
                    value={featureData.basic.price}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        basic: {
                          ...featureData.basic,
                          price: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Sats</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="2"
                    min={1}
                    value={featureData.standard.price}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        standard: {
                          ...featureData.standard,
                          price: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ marginTop: "auto", marginBottom: "auto" }}>Sats</p>
                  <input
                    style={numberInputStyle}
                    label="Number input"
                    id="typeNumber"
                    type="number"
                    placeholder="3"
                    min={1}
                    value={featureData.premium.price}
                    onChange={(event) =>
                      handleStateChange({
                        ...featureData,
                        premium: {
                          ...featureData.premium,
                          price: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </div>
    </>
  );
}

const inputStyle = {
  border: "none",
  backgroundColor: "transparent",
  resize: "none",
  outline: "none",
  width: "100%",
};

const numberInputStyle = {
  ...inputStyle,
  textAlign: "right",
};

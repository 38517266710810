import React, { useState } from "react";

export default function ImagePreview({ imageList, onFileRemoved }) {
  const [files, setFiles] = useState(imageList);

  function removeFile(index) {
    onFileRemoved(files[index].fileName);

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {files.map((file, index) => (
        <div key={index} style={{ margin: "10px", position: "relative" }}>
          <img
            src={`data:image/jpeg;base64,${file.data}`}
            alt="preview"
            style={{
              maxHeight: "100px",
              maxWidth: "100%",
              borderRadius: "10px",
            }}
          />
          <button
            onClick={() => removeFile(index)}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              borderRadius: "10px",
            }}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
}

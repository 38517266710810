import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function SearchBar({
  style,
  navigateOnEnter = true,
  setSearchQuery,
  startText = "",
}) {
  const [query, setQuery] = useState(startText);
  const navigate = useNavigate();

  function updateSearchQuery(event) {
    setQuery(event.target.value);
  }

  const handleSubmit = (event) => {
    event?.preventDefault();
    if (query !== "" && navigateOnEnter) {
      navigate("/search/" + query);
      return;
    }

    if (setSearchQuery !== undefined) {
      setSearchQuery(query);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [query]);
  return (
    <>
      <div style={{ ...style }}>
        <form className="d-flex input-group w-auto">
          <input
            type="search"
            className="form-control"
            placeholder={`Try "web design"`}
            aria-label="Search"
            onChange={(event) => updateSearchQuery(event)}
            value={query}
          />
          <MDBBtn
            onClick={handleSubmit}
            type="submit"
            style={{ backgroundColor: "orange", color: "white" }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ color: "white" }} />
          </MDBBtn>
        </form>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { FooterSubscribe } from "./FooterSubscribe";

export default function Footer() {
  return (
    <div style={{ marginTop: "auto" }}>
      <MDBFooter className="text-center" color="white" bgColor="dark">
        <p style={{ paddingBottom: "1rem", paddingTop: "1rem", marginBottom: "0rem" }}>Contact</p>
        <div style={{ marginLeft: "2rem", marginBottom: "2rem", marginRight: "2rem" }}>
          <FooterLink link="https://t.me/+vGVvP0AWxxVjOTkx" text="Join The Telegram Channel" />
          <FooterLink link="https://twitter.com/bitcoinrippin" text="Message Us On X" />
        </div>
        <FooterSubscribe />

        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2024&nbsp;
          <a className="text-white" href="https://bitcoinerfreelance.com/">
            bitcoinerfreelance.com
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}

function FooterLink({ link, text }) {
  return (
    <div
      style={{ display: "flex", marginBottom: "1rem" }}
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <MDBIcon fas icon="link" size="1x" />

      <p style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "1rem" }}>{text}</p>
    </div>
  );
}

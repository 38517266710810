import React from "react";
import FillWithMargin from "../Components/FillWithMargin";

function TermsOfService() {
  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Terms And Conditions</h1>
      <h1>Bitcoiner Freelance Terms of Service</h1>

      <p>Last Updated: April 23rd, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Bitcoiner Freelance (the "Platform"), you agree to comply with and be
        bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not
        use the Platform.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        Bitcoiner Freelance provides an online marketplace where freelancers can list their services
        and receive payment in Bitcoin. Users of the Platform can hire freelancers and pay for
        services using Bitcoin.
      </p>

      <h2>3. Registration and User Accounts</h2>
      <p>3.1. You must create a user account to use certain features of the Platform.</p>
      <p>3.2. You are responsible for maintaining the security of your account and password.</p>
      <p>
        3.3. You agree to provide accurate and up-to-date information when creating your account.
      </p>

      <h2>4. User Conduct</h2>
      <p>4.1. You are solely responsible for your interactions with other users on the Platform.</p>
      <p>4.2. You agree not to engage in any of the following activities:</p>
      <ul>
        <li>a. Violating any applicable laws or regulations.</li>
        <li>b. Posting false, misleading, or fraudulent information.</li>
        <li>c. Harassing, threatening, or abusing other users.</li>
        <li>d. Uploading or sharing content that is offensive, obscene, or harmful.</li>
        <li>e. Attempting to gain unauthorized access to the Platform.</li>
      </ul>

      <h2>5. Payments</h2>
      <p>
        5.1. Bitcoiner Freelance facilitates payments in Bitcoin between users. Users are
        responsible for ensuring the accuracy of payment details.
      </p>
      <p>
        5.2. Bitcoin transactions are irreversible. We are not responsible for any lost or stolen
        Bitcoin.
      </p>
      <p>
        5.3. Service fees may apply to transactions on the Platform. Details of these fees can be
        found in our separate fee schedule.
      </p>

      <p>
        5.4. Users are responsible for any taxes that may apply to payments made or received on the
        Platform.
      </p>

      <p>
        5.5. Users agree not to share any contact information, and only communicate with other users
        using the messaging tool built into the website.
      </p>

      <p>
        5.6. Users agree not to make payments outside of the Platform. Users who violate this rule
        may be banned from the Platform.
      </p>

      <p>
        5.7 There is a 10% fee on all withdrawals. Please email withdrawals@bitcoinerfreelance.com
        to initaite a withdrawal.
      </p>

      <h2>6. Dispute Resolution</h2>
      <p>6.1. Users are encouraged to resolve disputes amongst themselves.</p>
      <p>
        6.2. If a dispute cannot be resolved, Bitcoiner Freelance may assist in mediation, but we
        are not obligated to do so.
      </p>
      <p>6.3. Users agree to cooperate in any dispute resolution process.</p>

      <h2>7. Intellectual Property</h2>
      <p>7.1. Users retain ownership of the intellectual property related to their services.</p>
      <p>
        7.2. Users grant Bitcoiner Freelance a license to use their content for the purpose of
        promoting the Platform.
      </p>

      <h2>8. Privacy</h2>
      <p>
        8.1. Bitcoiner Freelance collects and uses personal information. We do not share this
        information with third parties except for those necessary to provide the service.
      </p>

      <h2>9. Termination</h2>
      <p>
        9.1. Bitcoiner Freelance reserves the right to suspend or terminate your account if you
        violate these Terms.
      </p>
      <p>9.2. You can terminate your account at any time by contacting us.</p>

      <h2>10. Changes to Terms</h2>
      <p>
        Bitcoiner Freelance may update these Terms from time to time. We will notify users of any
        significant changes.
      </p>

      <h2>11. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact us at [contact
        information].
      </p>

      <h2>12. Payment and Communication Guidelines</h2>
      <p>12.1. Users must only make payments using invoices generated by Bitcoiner Freelance.</p>
      <p>
        12.2. Users agree not to share any contact information, and only communicate with other
        users using the messaging tool built into the website.
      </p>
      <p>
        By signing up for this platform, you consent to receiving email notifications from Bitcoiner
        Freelance.
      </p>

      <p>
        By using Bitcoiner Freelance, you acknowledge that you have read, understood, and agreed to
        these Terms of Service.
      </p>
    </FillWithMargin>
  );
}

export default TermsOfService;

import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import Reviews from "../Reviews/Reviews";
import ReviewComments from "../Reviews/ReviewComments";
import Packages from "../Packages/Packages";
import ReviewCarousel from "../Reviews/ReviewCarousel";
import AboutThisGig from "../SellerProfile/AboutThisGig";
import AboutThisSeller from "../SellerProfile/AboutTheSeller";
import SellerInformationCard from "../SellerProfile/SellerInformationCard";
import ComparePackages from "./ComparePackages";
import ProfileAndRating from "../Profile/ProfileAndRating";
import GigImageCarousel from "./GigImageCarousel";
import CenterSpinner from "../Components/CenterSpinner";

import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import ComparePackagesMobile from "./ComparePackageMobile";
import FillWithMargin from "../Components/FillWithMargin";
import Button from "../Components/Button";
import EditButton from "./EditButton";
import BoostPostButton from "./BoostPostButton";
import BoostIcon from "../Components/BoostIcon";
import TitleEditAndBoostButton from "./TitleEditAndBoostButton";
Session.addAxiosInterceptors(axios);

export default function GigPage() {
  const isDesktop = useIsDesktop();
  const [gig, setGig] = useState({
    title: "",
    postedBy: "",
    sellerRating: 0,
    basicPrice: 0,
    basicDescription: "",
    standardPrice: 0,
    standardDescription: "",
    premiumPrice: 0,
    premiumDescription: "",
    reviews: [
      // {
      //   username: "",
      //   stars: 0,
      //   reviewText: "",
      //   dateLeft: "",
      // },
    ],
    jobDescription: "",
    images: [],
    features: [
      // {
      //   title: "",
      //   description: "",
      //   packageId: 0,
      //   parentId: 0,
      // }
    ],
  });
  const [profile, setProfile] = useState({
    from: "",
    memberSince: "",
    avgResponseTime: 0,
    lastDelivery: 0,
    biography: "",
    sellerStatement: "",
    profilePic: "",
  });
  const [message, setMessage] = useState("");
  const [editErrorMessage, setEditErrorMessage] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);
  let sessionContext = Session.useSessionContext();

  const { gigId } = useParams();

  useEffect(() => {
    // get gigId from params

    async function getShowEditButton() {
      // only show edit button if user is logged in and is the seller of the gig
      axios
        .get(getApiDomain() + "/showEditButton", {
          params: { gigId: gigId, userId: sessionContext.userId },
        })
        .then((response) => {
          setShowEditButton(response.data.showEditButton);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getShowEditButton();
  }, [setShowEditButton, gigId, sessionContext.userId]);

  useEffect(() => {
    async function getUser() {
      let response = await axios.get(getApiDomain() + "/gig", {
        params: { gigId: gigId },
      });
      setGig(response.data.gig);
      setProfile(response.data.profile);
    }
    // TODO AARON implement error handling and notify developers
    getUser().catch((err) => {
      console.log("there was an error: " + err);
      setMessage("There was an error loading this gig");
    });
  }, [gigId]);

  if (message !== "") {
    return (
      <>
        <FillWithMargin>
          <p>{message}</p>
        </FillWithMargin>
      </>
    );
  }

  if (gig.title === "") {
    return <CenterSpinner />;
  }

  return (
    <>
      <FillWithMargin>
        <div
          style={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <div style={{ width: "100%" }}>
            {editErrorMessage !== "" && <p style={{ color: "red" }}>{editErrorMessage}</p>}
            <TitleEditAndBoostButton
              showEditButton={showEditButton}
              gig={gig}
              gigId={gigId}
              setEditErrorMessage={setEditErrorMessage}
              isDesktop={isDesktop}
            />

            <ProfileAndRating job={gig} profile={profile} />
            <GigImageCarousel images={gig.images} />
            <div style={{ marginTop: "2rem" }}>
              <ReviewCarousel job={gig} />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <AboutThisGig jobDescription={gig.jobDescription} />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <AboutThisSeller job={gig} profile={profile} />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <SellerInformationCard profile={profile} />
            </div>
            {isDesktop && (
              <div style={{ marginTop: "2rem" }}>
                <ComparePackages job={gig} jobId={gigId} />
              </div>
            )}
            {!isDesktop && (
              <div style={{ marginTop: "2rem" }}>
                <ComparePackagesMobile job={gig} jobId={gigId} />
              </div>
            )}
            {profile && profile.userName !== "" && (
              <div style={{ marginTop: "2rem" }}>
                <Reviews userName={profile.userName} />
              </div>
            )}
          </div>
          {isDesktop && (
            <div
              style={{
                marginLeft: "2rem",
                width: "40%",
                marginTop: "",
              }}
            >
              <Packages job={gig} jobId={gigId} />
            </div>
          )}
        </div>
      </FillWithMargin>
    </>
  );
}

import { MDBInput } from "mdb-react-ui-kit";
import FillWithMargin from "../Components/FillWithMargin";
import React, { useEffect, useState } from "react";
import Button from "../Components/Button";
import Session, { validateClaims } from "supertokens-auth-react/recipe/session";
import axios from "axios";
import { getApiDomain } from "../App";
const { useParams } = require("react-router-dom");
const { useNavigate } = require("react-router-dom");
Session.addAxiosInterceptors(axios);

function AddLNURLPayAddress() {
  const [lnurlPayAddress, setLnurlPayAddress] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { gigId } = useParams();
  const navigate = useNavigate();

  async function verifyLNURLPayAddress() {
    setWaiting(true);
    setValidationMessage("");
    setSuccessMessage("");
    await axios
      .post(getApiDomain() + "/verifylnurlpayaddress", {
        params: {
          lnurlPayAddress: lnurlPayAddress,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.result === true) {
            setValidationMessage("");
            setSuccessMessage("Success! You can now post a gig.");
            navigate(`/post/${gigId}`);
          } else {
            setSuccessMessage("");
            setValidationMessage(
              "Something's not right with this address. Please contact your wallet provider and try again."
            );
          }
        }
      })
      .catch((err) => {
        setSuccessMessage("");
        setValidationMessage("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setWaiting(false);
      });
  }

  return (
    <>
      <FillWithMargin>
        <h1 style={{ fontWeight: "bold" }}>Add Lightning Address</h1>
        <p>
          Before you can post a gig, you need to provide your lightning address. This ligtning
          address should come from your ligtning-enabled bitcoin wallet.
        </p>

        <p>
          Lightning address look like email addresses, but allow someone else to request an invoice
          to pay you.
        </p>
        <p>
          If you don't have a wallet with a lightning address, we recommend the wallets on this
          list:{" "}
          <a
            href="https://app.mutinywallet.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            Mutiny
          </a>
          ,{" "}
          <a href="https://app.mash.com/" style={{ color: "blue", textDecoration: "underline" }}>
            Mash
          </a>
          ,{" "}
          <a href="https://stacker.news/" style={{ color: "blue", textDecoration: "underline" }}>
            Stacker News
          </a>
          ,{" "}
          <a href="https://getalby.com/" style={{ color: "blue", textDecoration: "underline" }}>
            Alby
          </a>
          , additionally, non-US customers may use{" "}
          <a
            href="https://www.walletofsatoshi.com/"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            Wallet of Satoshi
          </a>
        </p>
        <p style={{ fontWeight: "bold" }}>
          Make sure to enter only addresses YOU OWN because this is what users will use to pay you.
        </p>
        {validationMessage !== "" && <p style={{ color: "red" }}>{validationMessage}</p>}
        {successMessage !== "" && <p style={{ color: "green" }}>{successMessage}</p>}
        <MDBInput
          name="lnurlPayAddress"
          label="LNURL Pay Address"
          value={lnurlPayAddress}
          onInput={(event) => {
            setLnurlPayAddress(event.target.value);
          }}
        />
        <Button
          style={{ marginTop: "1rem" }}
          text={"SAVE & CONTINUE"}
          enabled={!waiting}
          onClick={verifyLNURLPayAddress}
        />
      </FillWithMargin>
    </>
  );
}

export default AddLNURLPayAddress;

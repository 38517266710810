import React from "react";
import { MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

const GigTable = ({ gig, gigPackage }) => {
  return (
    <MDBCard style={{ marginTop: "2rem", overflowX: "auto" }}>
      <MDBCardBody>
        <MDBTable responsive hover>
          <MDBTableHead>
            <tr>
              <th style={{ fontWeight: "bold" }}>Feature</th>
              <th style={{ fontWeight: "bold" }}>Description</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Delivery Time</td>
              <td>{gig[gigPackage + "DeliveryTime"]} days</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Revisions</td>
              <td>{gig[gigPackage + "Revisions"]}</td>
            </tr>
            {gig.features.map((feature, index) => (
              <tr key={index}>
                <td style={{ fontWeight: "bold" }}>{feature.title}</td>
                {gigPackage === "basic" && <td>{feature.basicDescription}</td>}
                {gigPackage === "standard" && <td>{feature.standardDescription}</td>}
                {gigPackage === "premium" && <td>{feature.premiumDescription}</td>}
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
  );
};

export default GigTable;

import React, { useState } from "react";
import ProfileSettings from "./ProfileSettings";
import FillWithMargin from "../Components/FillWithMargin";

export default function Settings() {
  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Edit Profile</h1>
      <ProfileSettings />
    </FillWithMargin>
  );
}

import { useState, useEffect } from "react";
import React from "react";
import { MDBInput, MDBCheckbox } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import FillWithMargin from "../Components/FillWithMargin";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import { useParams } from "react-router-dom";

export default function Subscribe() {
  let { unsubscribeGuid } = useParams();

  if (unsubscribeGuid === undefined) {
    unsubscribeGuid = "";
  }

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [claims, setClaims] = useState([]);
  const isDesktop = useIsDesktop();

  function subscribe() {
    setErrorMessage("");
    setDisabled(true);

    axios
      .post(getApiDomain() + "/subscribeToNotifications", {
        unsubscribeGuid: unsubscribeGuid,
        categories: categories
          .filter((category) => category.checked)
          .map((category) => category.id),
        selectAll: categories.every((category) => category.checked),
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err.response.data);
        setErrorMessage("An error occurred. Please try again.");
      })
      .finally(() => {
        setDisabled(false);
      });
  }

  useEffect(() => {
    async function loadData() {
      const cats = await fetchCategories();
      fetchClaims(cats);
    }
    setLoading(true);
    loadData().finally(() => {
      setLoading(false);
    });

    function fetchClaims(cats) {
      axios
        .get(getApiDomain() + "/getEmailClaims", { params: { unsubscribeGuid: unsubscribeGuid } })
        .then((res) => {
          let claims = res.data.claims;
          let newCategories = [...cats];
          claims.forEach((claim) => {
            newCategories.forEach((category) => {
              if (claim.claim_value === category.id) {
                category.checked = true;
              }
            });
          });
          setCategories(newCategories);
        })
        .catch((err) => {
          console.log("there was an error: " + err);
        });
    }
  }, [unsubscribeGuid]);

  async function fetchCategories() {
    try {
      const res = await axios.get(getApiDomain() + "/getGigCategories");
      let cats = res.data.categories.map((item) => {
        return {
          id: item.id,
          checked: false,
          category: item.category,
          count: item.count,
        };
      });
      return cats;
    } catch (error) {
      console.log("there was an error: " + error);
    }
  }

  function isEveryCategoryChecked() {
    return categories.every((category) => category.checked);
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Get Notified</h1>
      {loading ? (
        <CenterSpinner />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "start",
            }}
          >
            {errorMessage !== "" && <p style={{ color: "red" }}>{errorMessage}</p>}
            <p style={{ marginBottom: "1rem", padding: "0px 0px 0px 0px" }}>
              Subscribe to receive notifications when someone posts a new gig in a category you're
              interested in.
            </p>
            {categories.length > 0 ? (
              <>
                <div
                  style={{
                    marginBottom: "1rem",
                    display: "grid",
                    gridTemplateColumns: `repeat(${isDesktop ? 2 : 1}, 1fr)`,
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    <MDBCheckbox
                      checked={isEveryCategoryChecked()}
                      label="Select All"
                      onChange={() => {
                        let newCategories = [...categories];
                        let allChecked = newCategories.every((category) => category.checked);
                        newCategories.forEach((category) => (category.checked = !allChecked));
                        setCategories(newCategories);
                      }}
                    />
                  </div>
                  {categories.map((category, index) => (
                    <div key={index}>
                      <MDBCheckbox
                        id={category.id}
                        label={category.category}
                        checked={category.checked}
                        onChange={(e) => {
                          let newCategories = [...categories];
                          newCategories[index].checked = !newCategories[index].checked;
                          setCategories(newCategories);
                        }}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  style={{ width: "100%" }}
                  enabled={!disabled}
                  text={"Update Preferences"}
                  onClick={subscribe}
                />
              </>
            ) : (
              <CenterSpinner />
            )}
          </div>
        </>
      )}
    </FillWithMargin>
  );
}

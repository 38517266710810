import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import { MDBInput } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

import { getApiDomain } from "../App";
import axios from "axios";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export function FooterSubscribe() {
  const [existingUser, setExistingUser] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  let session = useSessionContext();
  let { doesSessionExist } = session;

  useEffect(() => {
    async function getUser() {
      try {
        let response = await axios.get(getApiDomain() + "/sessioninfo");
        if (response.data.user != null) {
          setEmail(response.data.user.email);
          setExistingUser(true);
        }
      } catch (error) {
        console.log("Navigation.js there was an error: " + error);
      }
    }
    if (session && session.userId !== undefined && session.userId !== "") {
      getUser();
    } else {
      setEmail("");
      setExistingUser(false);
    }
  }, [session?.userId]);

  function subscribe() {
    axios
      .post(getApiDomain() + "/createNewSubscriber", {
        email: email,
      })
      .then((res) => {
        let unsubscribeGuid = res.data.unsubscribeGuid;
        axios
          .post(getApiDomain() + "/subscribeToNotifications", {
            unsubscribeGuid: unsubscribeGuid,
            categories: [],
            selectAll: true,
          })
          .then((res) => {
            navigate(`/subscribe/${unsubscribeGuid}`);
          })
          .catch((err) => {
            console.log("there was an error: " + err);
          });
      })
      .catch((err) => {
        console.log("there was an error: " + err);
      });
  }

  return (
    <>
      {!doesSessionExist && (
        <div style={{ marginLeft: "2rem", marginBottom: "2rem", marginRight: "2rem" }}>
          <p>Get notified when new gigs are available</p>
          {!existingUser && (
            <MDBInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{ backgroundColor: "white" }}
              label="Enter Your Email"
            />
          )}

          <Button
            style={{ marginTop: existingUser ? "" : "1rem", width: "100%" }}
            onClick={subscribe}
            text={"Subscribe And Choose Categories"}
            enabled={existingUser || emailRegex.test(email)}
          ></Button>
        </div>
      )}
    </>
  );
}

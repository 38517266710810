import React, { useState } from "react";
import { MDBCollapse, MDBIcon } from "mdb-react-ui-kit";

export default function Accordion({ title, content, expanded = false }) {
  const [showShow, setShowShow] = useState(expanded);

  const toggleShow = () => setShowShow(!showShow);

  const arrowStyle = {
    transition: "transform 0.3s",
    transform: showShow ? "rotate(180deg)" : "rotate(0deg)",
    display: "inline-block",
    marginLeft: "0.5rem",
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: ".5rem",
        padding: "1rem",
      }}
    >
      <div
        onClick={toggleShow}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          height: "100%", // Added height to enable vertical alignment
        }}
      >
        <div>{title}</div>
        <span style={arrowStyle}>
          <MDBIcon fas icon="angle-down" />
        </span>
      </div>
      <div>
        <MDBCollapse show={showShow}>
          <div
            style={{
              paddingTop: "1rem",
              borderTop: "1px solid #ddd",
              marginTop: "1rem",
            }}
          >
            {content}
          </div>
        </MDBCollapse>
      </div>
    </div>
  );
}

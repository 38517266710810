import React, { useState, useEffect } from "react";
import { MDBCard, MDBRow, MDBCol, MDBCardFooter } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faPerson, faClock, faStar } from "@fortawesome/free-solid-svg-icons";
import FreelancerCard from "./FreelancerCard";
// import LinkButton from "../Components/LinkButton";

export default function SellerProfile({ cardStyle, userAccountData, iconColStyle }) {
  const [accountCreationDate, setAccountCreationDate] = useState("");

  useEffect(() => {
    if (userAccountData !== null && userAccountData.accountCreationDate !== "") {
      const date = new Date(userAccountData.accountCreationDate);
      const options = { month: "long", day: "numeric", year: "numeric" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      setAccountCreationDate(formattedDate);
    }
  }, [userAccountData]);

  if (userAccountData === null) {
    return <></>;
  }

  return (
    <MDBCard
      style={{
        ...cardStyle,
        width: "100%",
      }}
      key={1}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <FreelancerCard userAccountData={userAccountData}>
          {/* <LinkButton
            to={`/user/${userAccountData.userName}`}
            style={{ marginTop: "1rem" }}
            fullWidth
          >
            CONTACT ME
          </LinkButton> */}
        </FreelancerCard>
      </div>
      <MDBCardFooter>
        <MDBRow>
          <MDBCol style={iconColStyle} size="auto">
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon icon={faLocation} style={{ color: "orange" }} />
            </div>
          </MDBCol>
          <MDBCol>From</MDBCol>
          <MDBCol style={{ textAlign: "right" }}>{userAccountData.from}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol style={iconColStyle} size="auto">
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon icon={faPerson} style={{ color: "orange" }} />
            </div>
          </MDBCol>
          <MDBCol>Member Since</MDBCol>
          <MDBCol style={{ textAlign: "right" }}>{accountCreationDate}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol style={iconColStyle} size="auto">
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon icon={faClock} style={{ color: "orange" }} />
            </div>
          </MDBCol>
          <MDBCol>Last Delivery</MDBCol>
          <MDBCol style={{ textAlign: "right" }}>{userAccountData.lastDelivery} days</MDBCol>
        </MDBRow>
      </MDBCardFooter>
    </MDBCard>
  );
}

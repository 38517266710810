import React from "react";
import { Link } from "react-router-dom";
import { MDBListGroup, MDBListGroupItem, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function ReviewComments({ reviews }) {
  return (
    <>
      <MDBListGroup style={{ minWidthL: "22rem" }} light>
        {reviews.map((review, i) => {
          return (
            <MDBListGroupItem key={i}>
              <MDBRow>
                <MDBCol size="auto">
                  <img
                    src={review.profilePic}
                    style={{
                      height: "3em",
                      width: "3em",
                      borderRadius: "1.5em",
                      margin: 0,
                    }}
                    alt=""
                  ></img>
                </MDBCol>
                <MDBCol>
                  <MDBRow>
                    <MDBCol size="auto" style={{ padding: 0 }}>
                      <Link
                        to={`/user/${review.username}`}
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        {review.username}
                      </Link>
                    </MDBCol>
                    <MDBCol size="auto">
                      {[...Array(review.stars)].map((e, i) => {
                        return (
                          <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />
                        );
                      })}
                    </MDBCol>
                    <MDBCol style={{ padding: 0 }} size="auto">
                      <p
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {review.stars} stars
                      </p>
                    </MDBCol>
                    <MDBCol style={{ padding: 0 }}>
                      <p style={{ color: "gray" }}>&nbsp; {review.dateLeft}</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <p style={{ paddingLeft: "0" }}>{review.reviewText}</p>
                  </MDBRow>
                  {/* <MDBRow>
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <p className="small text-muted mb-0">Helpful?</p>
                      <i
                        className="far fa-thumbs-up mx-2 fa-xs text-black"
                        style={{ marginTop: "-0.16rem" }}
                      ></i>
                      <p className="small text-muted mb-0">Yes</p>
                      <i
                        className="far fa-thumbs-down mx-2 fa-xs text-black"
                        style={{ marginTop: "-0.16rem" }}
                      ></i>
                      <p className="small text-muted mb-0">No</p>
                    </div>
                  </MDBRow> */}
                </MDBCol>
              </MDBRow>
            </MDBListGroupItem>
          );
        })}
      </MDBListGroup>
    </>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol, MDBCardFooter } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Button from "../Components/Button";

export default function ReviewCarousel({ job }) {
  const [currentReview, setCurrentReview] = useState(0);

  function nextReview() {
    setCurrentReview((curr) => {
      return (curr + 1) % job.reviews.length;
    });
  }

  function previousReview() {
    setCurrentReview((curr) => {
      return (curr + job.reviews.length - 1) % job.reviews.length;
    });
  }

  return (
    <>
      {job.reviews.length > 0 ? (
        <>
          <p className="fs-5" style={{ fontWeight: "bold" }}>
            What people love about this seller
          </p>
          <div>
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="auto">
                    <img
                      src="https://mdbootstrap.com/img/new/fluid/city/113.webp"
                      style={{
                        height: "2rem",
                        width: "2rem",
                        borderRadius: "1rem",
                        margin: 0,
                      }}
                      alt=""
                    ></img>
                  </MDBCol>

                  <MDBCol size="auto" style={{ padding: 0 }}>
                    <Link
                      to={`/user/${job.reviews[currentReview].username}`}
                      style={{ color: "black" }}
                    >
                      {job.reviews[currentReview].username}
                    </Link>
                  </MDBCol>
                  <MDBCol size="auto">
                    {[...Array(job.reviews[currentReview].stars)].map((e, i) => {
                      return <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />;
                    })}
                  </MDBCol>
                  <MDBCol style={{ padding: 0 }}>
                    <MDBCardText
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {job.reviews[currentReview].stars} stars
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBCardText style={{ marginTop: "1rem" }}>
                  {job.reviews[currentReview].reviewText}
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter>{job.reviews[currentReview].dateLeft}</MDBCardFooter>
            </MDBCard>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button text={"Next"} onClick={nextReview} />
            <Button text={"Previous"} onClick={previousReview} style={{ marginLeft: "1rem" }} />
          </div>
        </>
      ) : (
        <p className="fs-5">This seller has no reviews yet</p>
      )}
    </>
  );
}

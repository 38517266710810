import React, { useState, useEffect, useRef, useContext } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import FillWithMargin from "../Components/FillWithMargin";
import UserContext from "../Context/UserContext";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";

const Messages = () => {
  const { orderId } = useParams();
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const { username } = useContext(UserContext);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (!loading) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, loading]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const response = await axios.get(getApiDomain() + "/getMessages", {
          params: {
            orderId: orderId,
          },
        });

        // Filter out the messages that have already been shown to the user
        const newMessages = response.data.messages.filter(
          (message) => !messages.some((m) => m.id === message.id)
        );

        if (newMessages.length > 0) {
          setMessages((prevMessages) => {
            const uniqueMessages = [...prevMessages, ...newMessages];
            const uniqueMessagesMap = new Map();

            uniqueMessages.forEach((message) => {
              uniqueMessagesMap.set(message.id, message);
            });

            const orderedMessages = Array.from(uniqueMessagesMap.values()).sort(
              (a, b) => a.id - b.id
            );

            return orderedMessages;
          });
        }

        setLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      }
    };
    getMessages();

    // Refresh messages every 3 seconds
    const interval = setInterval(() => {
      getMessages();
    }, 3000);

    return () => clearInterval(interval);
  }, [orderId, messages]);

  const sendMessage = async () => {
    try {
      const response = await axios.post(getApiDomain() + "/saveMessages", {
        params: {
          orderId: orderId,
          message: inputText,
        },
      });

      setMessages(response.data.messages);
    } catch (error) {
      console.log("There was an error:", error);
    }

    setInputText("");
  };

  function getDateTimeString(date) {
    let dateTime = new Date(date);

    // check to see if the date is today
    if (
      dateTime.getDate() === new Date().getDate() &&
      dateTime.getMonth() === new Date().getMonth() &&
      dateTime.getFullYear() === new Date().getFullYear()
    ) {
      return dateTime.toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    return dateTime.toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  function decodeFromHtml(message) {
    const parser = new DOMParser();

    // Use the parser to decode the HTML entities
    const decodedDocument = parser.parseFromString(message, "text/html");
    const decodedMessage = decodedDocument.documentElement.textContent;

    return decodedMessage;
  }

  if (loading) {
    return (
      <>
        <CenterSpinner />
      </>
    );
  }

  return (
    <>
      <FillWithMargin style={{}}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div>
            {messages.length === 0 && (
              <p style={{ textAlign: "center" }}>
                There are no messages yet for this order.
              </p>
            )}
            {messages.map((message, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column", // Added to display username above the message
                  alignItems:
                    message.username === username ? "flex-end" : "flex-start",
                }}
              >
                {message.username !== username && (
                  <p
                    style={{
                      alignSelf: "flex-start",
                      marginBottom: "0rem",
                      marginLeft: ".5rem",
                    }}
                  >
                    {message.username}
                  </p>
                )}
                {message.username === username && (
                  <p
                    style={{
                      alignSelf: "flex-end",
                      marginBottom: "0rem",
                      marginRight: ".5rem",
                    }}
                  >
                    You
                  </p>
                )}
                <div
                  style={{
                    backgroundColor:
                      message.username === username ? "orange" : "darkgrey",
                    color: "white",
                    ...bubbleStyle,
                    maxWidth: "75%",
                  }}
                >
                  {decodeFromHtml(message.message)}
                  <p
                    style={{
                      fontSize: ".75rem",
                      textAlign: "right",
                      marginBottom: "0rem",
                    }}
                  >
                    {getDateTimeString(message.timestamp)}
                  </p>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              position: "sticky",
              bottom: "0px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <div style={{ display: "flex", margin: "white" }}>
              <input
                type="text"
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "1rem",
                  border: "2px solid darkgrey",
                  outline: "none",
                }}
                placeholder="Type a message..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={sendMessage}
              >
                <MDBIcon
                  icon="paper-plane"
                  size="2x"
                  style={{ margin: ".5rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </FillWithMargin>
    </>
  );
};

const bubbleStyle = {
  padding: "1rem",
  borderRadius: "1rem",
  marginBottom: "1rem",
};

export default Messages;

import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Checkbox from "../Components/Checkbox";
import FormattedNumber from "../Components/FormattedNumber";

const AddonsTableWithoutCard = ({ addons, onSelect, showSelect = true }) => {
  const handleSelect = (checked, additionalCost) => {
    if (onSelect) {
      onSelect(checked, additionalCost);
    }
  };

  return (
    <MDBTable responsive hover style={{ width: "100%" }}>
      <MDBTableHead>
        <tr>
          <th style={{ fontWeight: "bold" }}>Add On</th>
          <th style={{ fontWeight: "bold" }}>Additional Cost</th>
          <th style={{ fontWeight: "bold" }}>Additional Time</th>
          {showSelect && <th style={{ fontWeight: "bold" }}>Select</th>}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {addons.map((addon, index) => (
          <tr key={index}>
            <td>{addon.title}</td>
            <td>
              <FormattedNumber number={addon.additionalCost} />
            </td>
            <td>{addon.additionalTime} days</td>
            {showSelect && (
              <td>
                <Checkbox
                  onChange={(checked) => handleSelect(checked, addon)}
                />
              </td>
            )}
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
  );
};

export default AddonsTableWithoutCard;

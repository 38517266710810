import React, { useEffect, useState } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LinkButton from "../Components/LinkButton";
const { MDBProgress, MDBProgressBar } = require("mdb-react-ui-kit");

function Delivery() {
  const { orderId } = useParams();

  const [deliveries, setDeliveries] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deliveryProgress, setDeliveryProgress] = useState([]);

  useEffect(() => {
    async function getDelivery() {
      setDeliveryLoading(true);
      try {
        const response = await axios.get(getApiDomain() + "/getDeliveredOrder", {
          params: {
            orderId: orderId,
          },
        });
        setDeliveries(response.data.deliveries);
        setDeliveryLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      } finally {
        setDeliveryLoading(false);
      }
    }

    getDelivery();
  }, [orderId]);

  async function downloadFiles(delivery) {
    setDownloadLoading(true);
    try {
      let downloadedFiles = [];
      for (let j = 0; j < delivery.files.length; j++) {
        const file = delivery.files[j];
        const totalChunks = file.totalChunks;
        let chunks = [];
        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          const chunkResponse = await axios.get(getApiDomain() + "/getChunk", {
            params: {
              orderId: orderId,
              chunkDetails: {
                fileName: file.fileName,
                chunkIndex: chunkIndex,
              },
            },
          });
          const chunk = chunkResponse.data.chunk.data;
          chunks = [...chunks, ...chunk];

          updateDeliveryProgress(file.fileName, chunkIndex, totalChunks);
        }
        downloadedFiles.push({ fileName: file.fileName, chunks: chunks });
      }
      for (let i = 0; i < downloadedFiles.length; i++) {
        downloadArray(downloadedFiles[i].chunks, downloadedFiles[i].fileName);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    } finally {
      setDownloadLoading(false);
    }
  }

  function updateDeliveryProgress(fileName, chunkIndex, totalChunks) {
    const progress = Math.floor(((chunkIndex + 1) / totalChunks) * 100);

    setDeliveryProgress((prev) => [
      ...prev.filter((del) => del.fileName !== fileName),
      { fileName: fileName, progress: progress },
    ]);
  }

  function downloadArray(array, filename) {
    // download as mp4
    const blob = new Blob([new Uint8Array(array)], { type: "video/mp4" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Append the <a> element to the document body
    document.body.appendChild(link);

    // Click the link to trigger the download
    link.click();

    // Remove the temporary <a> element
    document.body.removeChild(link);

    // Revoke the URL to release the resources
    window.URL.revokeObjectURL(url);
  }

  function getProgress(file) {
    return deliveryProgress.filter((del) => del.fileName === file.fileName).length > 0
      ? deliveryProgress.filter((del) => del.fileName === file.fileName)[0].progress
      : 0;
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Your Order Delivery</h1>
      <p>Please see your delivered order below.</p>
      {deliveryLoading ? (
        <CenterSpinner />
      ) : (
        <>
          {deliveries.map((delivery, index) => {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                {delivery.revisionId !== null && (
                  <p style={{ fontStyle: "italic" }}>You requested a revision for this order.</p>
                )}
                <p>
                  <span style={{ fontWeight: "bold" }}>Date: </span>
                  {delivery.createdAt}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Description: </span>
                  {delivery.deliveryDescription}
                </p>
                {delivery.files.length > 0 && (
                  <>
                    {delivery.files.map((file, index) => {
                      return (
                        <div key={index}>
                          <p>
                            <span style={{ fontWeight: "bold" }}>File Name: </span>
                            {file.fileName}
                          </p>
                          {downloadLoading && (
                            <div style={{ marginTop: "auto", marginBottom: "1rem" }}>
                              <MDBProgress height="6">
                                <MDBProgressBar
                                  width={getProgress(file)}
                                  valuemin={0}
                                  valuemax={100}
                                  style={{ backgroundColor: "orange" }}
                                />
                              </MDBProgress>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
                <div style={{ display: "flex" }}>
                  {delivery.files.length > 0 && (
                    <Button
                      onClick={() => downloadFiles(delivery)}
                      text={"Download Files"}
                      enabled={!downloadLoading}
                    />
                  )}
                  {delivery.revisionId !== null && (
                    <LinkButton style={{ marginLeft: ".5rem" }} to={`/revisions/${orderId}`}>
                      VIEW REVISIONS
                    </LinkButton>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </FillWithMargin>
  );
}

export default Delivery;

import React from "react";
import HeroSection from "./HeroSection";
import BrowseByCategory from "./ExploreTheMarketplace";
import CallToAction from "./CallToAction";
import FeaturedGigs from "./FeaturedGigs";
import { useIsDesktop } from "../Hooks/WindowDimensions";

export default function Home() {
  const isDesktop = useIsDesktop();
  const margin = isDesktop ? "2rem" : "1rem";
  return (
    <div className="fill" style={{ width: "100%" }}>
      <HeroSection />
      <div style={{ marginTop: margin, marginLeft: margin, marginRight: margin }}>
        <BrowseByCategory />
      </div>
      <div style={{ marginTop: margin }}>
        <CallToAction />
      </div>
      <div style={{ margin: margin }}>
        <FeaturedGigs />
      </div>
    </div>
  );
}

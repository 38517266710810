import React from "react";
import Button from "../Components/Button";
import { getApiDomain } from "../App";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
Session.addAxiosInterceptors(axios);

function EditButton({ gigId, setError, style }) {
  const navigate = useNavigate();

  function handleClick() {
    axios
      .post(getApiDomain() + "/editgig", {
        params: { gigId: gigId },
      })
      .then((response) => {
        if (response.status === 200) {
          navigate("/post/select-gig");
        }
      })
      .catch((error) => {
        if (setError && error && error.response && error.response.data) {
          setError(error.response.data);
        }
      });
  }

  return (
    <span style={style}>
      <Button text={"Edit"} onClick={handleClick} />
    </span>
  );
}

export default EditButton;

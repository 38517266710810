import React from "react";
import { MDBSpinner } from "mdb-react-ui-kit";

export default function CenterSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2rem",
      }}
    >
      <MDBSpinner
        role="status"
        style={{
          border: "0.25em solid orange",
          borderRightColor: "transparent",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    </div>
  );
}

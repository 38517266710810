import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import GigCard from "./GigCard";
import CenterSpinner from "../Components/CenterSpinner";
import { MDBCol } from "mdb-react-ui-kit";

function GigCardById({ gigId, serachText }) {
  const [gigInfo, setGigInfo] = useState(null);

  useEffect(() => {
    if (gigId === null) return;
    axios
      .get(getApiDomain() + "/getGigInfoFromIds", {
        params: {
          gigId: gigId,
        },
      })
      .then((res) => {
        setGigInfo(res.data.job);
      })
      .catch((err) => {
        console.log("there was an error: " + err); // TODO catch error
      });
  }, [gigId]);

  if (!gigInfo) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  if (serachText !== "" && serachText !== null) {
    if (!gigInfo.title.toLowerCase().includes(serachText.toLowerCase())) {
      return null;
    }
  }

  return (
    <MDBCol>
      <GigCard job={gigInfo} />
    </MDBCol>
  );
}

export default GigCardById;

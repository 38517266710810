import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiDomain } from "../App";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import FillWithMargin from "../Components/FillWithMargin";
import CenterSpinner from "../Components/CenterSpinner";
import { useNavigate } from "react-router-dom";

Session.addAxiosInterceptors(axios);

function BoostGig() {
  let { gigId, amount } = useParams();
  const [checkoutLink, setCheckoutLink] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function createBoostInvoice() {
      try {
        const response = await axios.post(getApiDomain() + "/createBoostInvoice", {
          params: { gigId: gigId, amount: amount },
        });
        if (response.status === 200) {
          setCheckoutLink(response.data.checkoutLink);
          setInvoiceId(response.data.invoice.invoice.id);
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          // setError(error.response.data);
        }
      }
    }
    createBoostInvoice();
  }, []);

  // call checkIsInvoicePaid every 5 seconds
  useEffect(() => {
    async function checkIsInvoicePaid() {
      // check DB to see if invoice is paid
      if (invoiceId === null) return;
      await axios
        .get(getApiDomain() + "/checkIsInvoicePaid", {
          params: { invoiceId: invoiceId },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.isPaid) {
              navigate(`/gig/${gigId}`);
            }
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            // setError(error.response.data);
          }
        });
    }

    const interval = setInterval(() => {
      checkIsInvoicePaid();
    }, 5000);
    return () => clearInterval(interval);
  }, [invoiceId, gigId]);

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Boost Your Gig</h1>
      {checkoutLink === "" ? (
        <CenterSpinner />
      ) : (
        <>
          <iframe
            src={checkoutLink}
            style={{ width: "100%", height: "100vh" }}
            title="Boost Checkout"
          ></iframe>
        </>
      )}
    </FillWithMargin>
  );
}

export default BoostGig;

import { useParams } from "react-router-dom";
import GigGrid from "../SellerProfile/GigGrid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import SearchBar from "../SearchBar/SearchBar";
import CenterSpinner from "../Components/CenterSpinner";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import FillWithMargin from "../Components/FillWithMargin";

export default function Search() {
  // params
  let { query } = useParams();

  if (query === undefined) {
    query = "";
  }

  query = query.replace("_", " ");

  // state
  const [jobs, setJobs] = useState([]);
  const isDesktop = useIsDesktop();
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState(query);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (query !== "") {
      getJobs(query);
    }
  }, []);

  async function getJobs(searchText) {
    if (searchText === "") {
      setSearchQuery("");
      setMessage("Please enter a search query");
      return;
    } else {
      setMessage("");
      setSearchQuery(searchText);
    }

    setSearching(true);
    await axios
      .get(getApiDomain() + "/searchJobs", {
        params: { query: searchText, limit: 100 },
      })
      .then((response) => {
        if (response.status === 200) {
          setJobs(response.data.jobs);
        }
      })
      .catch((err) => {
        let message = err.response.data.message; // TODO handle any errorswebsite desig
        // setFeatureGridErrorMessage(message);
      })
      .finally(() => {
        setSearching(false);
      });
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Search</h1>
      <div style={{ marginTop: isDesktop ? "2rem" : "1rem" }}>
        <SearchBar
          startText={searchQuery}
          navigateOnEnter={false}
          setSearchQuery={(value) => {
            getJobs(value);
          }}
        />
      </div>
      {message !== "" && <p style={{ color: "Red" }}>{message}</p>}
      <div style={{ marginTop: isDesktop ? "2rem" : "1rem" }}>
        {searchQuery && (searchQuery !== null) & (searchQuery.length > 0) && (
          <h3 style={{ fontWeight: "bold" }}>{searchQuery.replace("_", " ")}</h3>
        )}
        {jobs && !searching ? (
          <>
            <>
              <p>
                {jobs.length} {jobs.length === 1 ? "service" : "services"} available
              </p>
            </>
            <GigGrid jobs={jobs} gridLayout={"row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"} />
          </>
        ) : (
          <div className="fill">
            <CenterSpinner />
          </div>
        )}
      </div>
    </FillWithMargin>
  );
}

import React from "react";
import Session from "supertokens-auth-react/recipe/session";
import CenterSpinner from "./CenterSpinner";
import FillWithMargin from "./FillWithMargin";

export default function SessionComponent({ children }) {
  let sessionContext = Session.useSessionContext();

  if (sessionContext.loading) {
    return (
      <>
        <FillWithMargin>
          <CenterSpinner />
        </FillWithMargin>
      </>
    );
  }

  if (sessionContext.doesSessionExist) {
    return <>{children}</>;
  } else {
    <p>You must be logged in to view this page.</p>;
  }

  return null;
}

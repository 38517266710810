import React from "react";
import { useNavigate } from "react-router-dom";

function BFLink({ to, children, onClick }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    onClick && onClick();
    navigate(to);
  };

  return <span onClick={handleClick}>{children}</span>;
}

export default BFLink;

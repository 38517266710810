import React from "react";
import { Link } from "react-router-dom";
import { MDBCardText, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function AboutThisSeller({ job, profile }) {
  return (
    <>
      <p className="fs-5" style={{ fontWeight: "bold" }}>
        About The Seller
      </p>
      <MDBRow>
        <MDBCol size="auto">
          <img
            src={profile.profilePic} // TODO get profile pic
            style={{
              height: "8em",
              width: "8em",
              borderRadius: "4em",
              margin: 0,
              objectFit: "cover",
            }}
            alt=""
          ></img>
        </MDBCol>
        <MDBCol>
          <Link to={`/user/${job.postedBy}`} style={{ color: "black", fontWeight: "bold" }}>
            {job.postedBy}
          </Link>
          <p style={{ marginBottom: "0" }}>{profile.biography}</p>
          <MDBRow>
            <MDBCol size="auto">
              {[...Array(job.sellerRating)].map((e, i) => {
                return <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />;
              })}
            </MDBCol>
            <MDBCol style={{ padding: 0 }}>
              <MDBCardText
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {job.sellerRating} stars
              </MDBCardText>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
}

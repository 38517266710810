import React from "react";

import SearchBar from "../SearchBar/SearchBar";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import pexels_1 from "../images/pexels_1.jpg";
import LinkButton from "../Components/LinkButton";

export default function HeroSection() {
  const isDesktop = useIsDesktop();

  return (
    <>
      <div
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: `url(${pexels_1})`,
          height: "50vh",
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-white">
              <h1 style={{ fontWeight: "bold", textAlign: "left" }}>
                Find freelancers that accept Bitcoin
              </h1>
              <SearchBar style={{ width: isDesktop ? "50%" : "100%" }} />
              <div
                style={{
                  marginTop: "1rem",
                }}
              >
                {isDesktop && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Popular:{" "}
                    </p>
                    <div style={{ display: "block", marginLeft: "1rem" }}>
                      <LinkButton to="/search/website_design">Website&nbsp;Design</LinkButton>
                      <LinkButton to="/search/wordpress">WordPress</LinkButton>
                      <LinkButton to="/search/logo_design">Logo&nbsp;Design</LinkButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import LinkButton from "../Components/LinkButton";

function RevisionCard({ revision, children }) {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <p>
        <span style={{ fontWeight: "bold" }}>Date: </span>
        {revision.datetime}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Status: </span>
        {revision.status}
      </p>
      {revision.completedDateTime !== null && (
        <p>
          <span style={{ fontWeight: "bold" }}>Completed At: </span>
          {revision.completedDateTime}
        </p>
      )}
      <p>
        <span style={{ fontWeight: "bold" }}>Description: </span>
        {revision.description}
      </p>
      {children}
    </div>
  );
}

export default RevisionCard;

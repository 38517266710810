import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function ImageFileUpload({
  onFilesChange,
  setErrorMessage,
  maxFiles = 10,
}) {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const inputRef = useRef();
  const [hovering, setHovering] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();
  };

  // grab files from server and preset them in the file upload component

  function updateFiles(e) {
    setFiles(e, "a");
    //onFilesChange(e);
  }

  useEffect(() => {
    onFilesChange(files);
  }, [files, onFilesChange]);

  return (
    <div style={{ padding: "0px", margin: "0px" }}>
      <div style={{ marginTop: "0px" }}>
        {/* Provide a drop zone and an alternative button inside it to upload files. */}
        <div
          style={{
            backgroundColor: "#f5f5f5",
            marginTop: "0px",
            textAlign: "center",
            padding: "2rem",
            borderRadius: "5px",
            borderWidth: "2px",
            borderColor: hovering ? "orange" : "#f5f5f5",
            borderStyle: "dashed",
          }}
          onDragEnter={handleDragDropEvent}
          onMouseEnter={() => {
            setHovering(true);
          }}
          onMouseLeave={() => {
            setHovering(false);
          }}
          onDragOver={handleDragDropEvent}
          onDrop={(event) => {
            handleDragDropEvent(event);
            const droppedFiles = event.dataTransfer.files;
            const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
            const isImage = [...droppedFiles].every((file) =>
              allowedTypes.includes(file.type)
            );
            const isWithinMaxFiles =
              droppedFiles.length <= maxFiles &&
              files.length + droppedFiles.length <= maxFiles;
            if (isImage && isWithinMaxFiles) {
              updateFiles(event);
              setErrorMessage("");
            } else {
              // alert("Only image files are allowed");
              if (!isImage) {
                setErrorMessage("Only image files are allowed");
              }

              if (!isWithinMaxFiles) {
                setErrorMessage(`Only ${maxFiles} files are allowed`);
              }
            }
          }}
        >
          <p>Drag and drop files here</p>

          <MDBBtn
            onClick={() => inputRef.current.click()}
            style={{ backgroundColor: "orange" }}
          >
            Or select files to upload
          </MDBBtn>

          {/* Hide the crappy looking default HTML input */}
          <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => {
              if (
                e.target.files.length > maxFiles ||
                files.length + e.target.files.length > maxFiles
              ) {
                setErrorMessage(`Only ${maxFiles} files are allowed`);
                return;
              }
              updateFiles(e);
              inputRef.current.value = null;
            }}
          />
        </div>

        {/* Display the files to be uploaded */}
        <div style={{ marginTop: "2rem" }}>
          {fileNames.length > 0 && (
            <>
              <MDBTable bordered style={{ marginBottom: "0px" }}>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Filename</th>
                    <th scope="col"></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {fileNames.map((name, index) => {
                    return (
                      <tr key={index}>
                        <td>{name}</td>
                        <td>
                          <MDBIcon
                            far
                            icon="minus-square"
                            style={{ color: "orange" }}
                            onClick={() => removeFile(name)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </>
          )}

          {files.length > 0 && <p>Total Size: {totalSize}</p>}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import { MDBBtn } from "mdb-react-ui-kit";
import CustomFeatureGrid from "./CustomFeatureGrid";
import StandardFeatureGrid from "./StandardFeatureGrid";
import AddonsGrid from "./AddonsGrid";
import FillWithMargin from "../Components/FillWithMargin";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CenterSpinner from "../Components/CenterSpinner";

export default function GigAttributes() {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    standardFeatures: {
      basic: {
        description: "",
        deliveryTime: 1,
        revisions: 1,
        price: 1,
      },
      standard: {
        description: "",
        deliveryTime: 2,
        revisions: 2,
        price: 3,
      },
      premium: {
        description: "",
        deliveryTime: 3,
        revisions: 3,
        price: 3,
      },
    },
    customFeatures: [
      { id: 0, title: "", packages: [{ description: "", packageId: 0 }] },
      { id: 0, title: "", packages: [{ description: "", packageId: 0 }] },
      { id: 0, title: "", packages: [{ description: "", packageId: 0 }] },
    ],
    addons: [
      {
        id: 0,
        title: "",
        additionalCost: 1,
        additionalTime: 1,
      },
    ],
  });
  const [featureGridErrorMessage, setFeatureGridErrorMessage] = useState("");
  const [customFeaturesGridErrorMessage, setcustomFeaturesGridErrorMessage] = useState("");
  const [addonsGridErrorMessage, setaddonsGridErrorMessage] = useState("");
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const { gigId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getFormData() {
      await axios
        .get(getApiDomain() + "/getGigFeatures", {
          params: { gigId: gigId },
        })
        .then((response) => {
          if (response.status === 200) {
            setFormData(response.data);
          }
        })
        .catch((err) => {
          setGeneralErrorMessage("An error occurred.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getFormData();
  }, []);

  async function saveFormData() {
    setSaveButtonDisabled(true);
    axios
      .post(getApiDomain() + "/saveGigFeatures", { formData: formData, gigId: gigId })
      .then((response) => {
        if (response.status === 200) {
          setFeatureGridErrorMessage("");
          setcustomFeaturesGridErrorMessage("");
          setaddonsGridErrorMessage("");
          setGeneralErrorMessage("");
          navigate(`/post/${gigId}/description-and-faq`);
        }
      })
      .catch((err) => {
        let standardFeaturesError = err.response.data.standardFeaturesError;
        setFeatureGridErrorMessage(standardFeaturesError);

        let customFeaturesError = err.response.data.customFeaturesError;
        setcustomFeaturesGridErrorMessage(customFeaturesError);

        let addonsError = err.response.data.addonsError;
        setaddonsGridErrorMessage(addonsError);

        let generalError = err.response.data.generalError;
        setGeneralErrorMessage(generalError);
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  }

  function featureGridChange(newState) {
    setFormData({ ...formData, standardFeatures: newState });
  }

  function customFeatureGridChange(newState) {
    setFormData({ ...formData, customFeatures: newState });
  }

  function addonsGridChange(newState) {
    setFormData({ ...formData, addons: newState });
  }

  return (
    <>
      <FillWithMargin>
        <p>2 of 6</p>
        <h1 style={{ fontWeight: "bold" }}>Enter Basic Information</h1>
        {generalErrorMessage !== "" && (
          <div className="mb-4" style={{ color: "red" }}>
            {generalErrorMessage}
          </div>
        )}
        <div className="mb-4">
          Enter basic information about your gig. Max length is 255 characters.
        </div>
        {loading ? (
          <CenterSpinner />
        ) : (
          <>
            <StandardFeatureGrid
              initialData={formData.standardFeatures}
              onStateChange={featureGridChange}
            />
            {featureGridErrorMessage !== "" && (
              <div className="mb-4" style={{ color: "red" }}>
                {featureGridErrorMessage}
              </div>
            )}
            <div className="mb-4">
              Features that your customers will see when viewing your listing.
            </div>
            <CustomFeatureGrid
              initialData={formData.customFeatures}
              onStateChange={customFeatureGridChange}
            />
            {customFeaturesGridErrorMessage !== "" && (
              <div className="mb-4" style={{ color: "red" }}>
                {customFeaturesGridErrorMessage}
              </div>
            )}
            <div className="mb-4">Optional addons to any package.</div>
            <AddonsGrid initialData={formData.addons} onStateChange={addonsGridChange} />
            {addonsGridErrorMessage !== "" && (
              <div className="mb-4" style={{ color: "red" }}>
                {addonsGridErrorMessage}
              </div>
            )}

            <div>
              <MDBBtn
                type="submit"
                block
                style={{ backgroundColor: "orange" }}
                onClick={saveFormData}
                disabled={saveButtonDisabled}
              >
                Save & Continue
              </MDBBtn>
            </div>
          </>
        )}
      </FillWithMargin>
    </>
  );
}

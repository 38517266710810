import React, { useEffect, useState, useCallback } from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import FillWithMargin from "../Components/FillWithMargin";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";

export default function AddBoost() {
  const navigate = useNavigate();
  const { gigId } = useParams();
  const [minBoost, setMinBoost] = useState(null);
  const [category, setCategory] = useState("");
  const [currentBoost, setCurrentBoost] = useState(null);
  const [gigs, setGigs] = useState(null);
  const [selectedBoostAmount, setSelectedBoostAmount] = useState(0);
  const [requiredBoostAmount, setRequiredBoostAmount] = useState(0);
  const [generalError, setGeneralError] = useState("");

  useEffect(() => {
    async function fetchData() {
      axios
        .get(getApiDomain() + "/featuredGigData")
        .then((response) => {
          if (response.status === 200) {
            let gigs = response.data.gigs;
            let minBoost = gigs[gigs.length - 1].total_boost;
            if (minBoost === null) {
              minBoost = 0;
            }
            setMinBoost(minBoost);
          }
        })
        .catch((error) => {});

      axios
        .get(getApiDomain() + "/minSatsPerCategory", {
          params: { gigId: gigId },
        })
        .then((response) => {
          if (response.status === 200) {
            let gigs = response.data.gigs;
            setGigs(gigs);

            if (gigs.length < 1) {
            } else {
              setCategory(gigs[0].category);
            }
          }
        })
        .catch((error) => {});

      axios
        .get(getApiDomain() + "/minBoostToPost")
        .then((response) => {
          let reqBoost = 0;
          if (response.status === 200) {
            reqBoost = +response.data.minBoost;
          }

          axios
            .get(getApiDomain() + "/currentBoost", {
              params: { gigId: gigId },
            })
            .then((response) => {
              if (response.status === 200) {
                setCurrentBoost(response.data.boostAmount);
                if (+response.data.boostAmount > 0 && +response.data.boostAmount < reqBoost) {
                  setSelectedBoostAmount(reqBoost - response.data.boostAmount);
                }

                setRequiredBoostAmount(reqBoost);
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }

    if (gigId) {
      fetchData();
    }
  }, [gigId]);

  function minBoostBasedOnPercent(gigs, percentage) {
    if (gigs.length < 1) {
      return 0;
    }

    let totalBoosts = gigs.map((gig) => +gig.total_boost);
    totalBoosts = totalBoosts.filter((boost) => boost !== null);
    totalBoosts.sort((a, b) => b - a);
    let cutoff = totalBoosts[Math.floor(totalBoosts.length * percentage)];
    if (cutoff === null) {
      cutoff = 0;
    }

    return cutoff;
  }

  async function confirmBoost() {
    setGeneralError("");
    if (selectedBoostAmount + currentBoost < requiredBoostAmount) {
      setGeneralError("You must add enough sats to meet the required boost amount");
    } else {
      navigate(`/gig/${gigId}/boost/${selectedBoostAmount}`);
    }
  }

  function isLoading() {
    return currentBoost === null || minBoost === null || gigs === null;
  }

  function skipBoostAndPostGig() {
    axios.post(getApiDomain() + "/tryPostGig", { gigId: gigId }).then((response) => {
      if (response.status === 200) {
        navigate(`/gig/${gigId}`);
      } else {
        alert("An error occurred. Please try again.");
      }
    });
  }

  return (
    <>
      <FillWithMargin>
        {isLoading() ? (
          <CenterSpinner />
        ) : (
          <>
            <p>6 of 6</p>
            <h1 style={{ fontWeight: "bold" }}>Add a boost</h1>
            {generalError && <p style={{ color: "red" }}>{generalError}</p>}
            <p>To post your gig, you must add a boost of at least {requiredBoostAmount} sats</p>
            <p>
              Boosts influence the rank of your gig in search results. The more you boost your post,
              the higher it is likely to appear.
            </p>
            <p>The minimum boost required to make it to the featured section is {minBoost} sats.</p>
            <p>
              In <b>{category}</b>, it would take{" "}
              <b>{+minBoostBasedOnPercent(gigs, 0.1) + 1} or more sats</b> to rank in the top 10% of
              gigs.
            </p>
            {currentBoost > 0 && (
              <p>
                Your gig currently has <b>{currentBoost} sats boosted</b>.{" "}
                {currentBoost < requiredBoostAmount && (
                  <span>
                    You need to boost at least{" "}
                    <b>{requiredBoostAmount - currentBoost} sats to post your gig</b>.{" "}
                  </span>
                )}
              </p>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ⚡{" "}
              <MDBInput
                id="typeNumber"
                type="number"
                value={selectedBoostAmount}
                onChange={(e) => {
                  setSelectedBoostAmount(+e.target.value);
                }}
              />
              &nbsp;sats
            </div>
            <MDBBtn
              style={{ backgroundColor: "orange", marginTop: "1rem" }}
              onClick={() => {
                confirmBoost();
              }}
            >
              Confirm Boost & Post Gig
            </MDBBtn>
            {currentBoost >= requiredBoostAmount && (
              <div style={{ marginTop: "1rem" }}>
                <p>
                  Your gig currently has enough gigs to post. Would you like to skip adding a boost,
                  and post your gig?
                </p>
                <MDBBtn
                  onClick={() => {
                    skipBoostAndPostGig();
                  }}
                >
                  Skip Boost
                </MDBBtn>
              </div>
            )}
          </>
        )}
      </FillWithMargin>
    </>
  );
}

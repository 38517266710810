import React, { useEffect, useState } from "react";
import { MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import duck from "../images/duck.jpg";
import axios from "axios";
import { getApiDomain } from "../App";

export default function FreelancerCard({ userAccountData, children }) {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    async function getRating() {
      await axios
        .get(getApiDomain() + "/sellerRating", {
          params: { username: userAccountData.userName },
        })
        .then((response) => {
          setRating(response.data.rating);
        })
        .catch((err) => {
          console.log("there was an error: " + err); // TODO catch error
        });
    }
    // // TODO AARON implement error handling and notify developers
    getRating();
  }, [userAccountData.userName]);

  return (
    <MDBCardBody style={{ width: "100%" }}>
      <MDBCardImage
        position="top"
        src={userAccountData.profilePic !== "" ? userAccountData.profilePic : duck}
        style={{
          height: "10rem",
          width: "10rem",
          borderRadius: "5rem",
          marginBottom: "2rem",
          objectFit: "cover",
        }}
      ></MDBCardImage>
      <MDBCardTitle style={{ fontWeight: "bold" }}>{userAccountData.userName}</MDBCardTitle>
      <MDBCardText>{userAccountData.accountBio}</MDBCardText>
      <MDBCardText style={{ fontWeight: "bold" }}>
        {[...Array(userAccountData.rating)].map((e, i) => {
          return <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />;
        })}{" "}
        {rating} stars
      </MDBCardText>
      {children}
    </MDBCardBody>
  );
}

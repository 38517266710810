import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useParams, useNavigate } from "react-router-dom";
import FillWithMargin from "../Components/FillWithMargin";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import Button from "../Components/Button";
import FormattedNumber from "../Components/FormattedNumber";
import { useLocation } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

function PayInvoice() {
  const { gigId, gigPackage } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [price, setPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const state = location.state;
  const [timeRemaining, setTimeRemaining] = useState(1000);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvoiceExpired, setIsInvoiceExpired] = useState(false);
  const navigate = useNavigate();

  const memo = `Payment for "${state.gig.title}" on BitcoinerFreelance.com`;

  const setInvoicePaid = async () => {
    setLoading(true);
    await axios
      .post(getApiDomain() + "/updateInvoiceSetPaid", {
        invoice: invoice,
      })
      .then((response) => {
        if (response.status === 200) {
          let orderId = response.data.orderId;
          navigate(`/requirements/${orderId}`);
        } else {
          setErrorMessage("Invoice not paid. Please try again later.");
        }
      })
      .catch((err) => {
        setErrorMessage("An error occurred. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchNewInvoice = async () => {
    setFetching(true);
    setErrorMessage("");
    await axios
      .post(getApiDomain() + "/generateNewInvoice", {
        gigId: gigId,
        gigPackage: gigPackage,
        addons: state.addonsSelected,
        memo: memo,
      })
      .then((response) => {
        setInvoice(response.data.data.invoice);
        const expiresAt = new Date(response.data.data.invoice.expiresAt).getTime();
        const currentTime = new Date().getTime();
        setTimeRemaining(Math.floor((expiresAt - currentTime) / 1000));
        setIsInvoiceExpired(false);
      })
      .catch((error) => {
        console.log("There was an error:", error);
        setErrorMessage("An error occurred. Please try again later.");
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchNewInvoice();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    const setInvoiceExpiredApiCall = async () => {
      setLoading(true);
      await axios
        .post(getApiDomain() + "/setInvoiceExpired", {
          invoice: invoice,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
            } else {
              setErrorMessage("Could not set invoice expired. Please try again later.");
            }
          }
        })
        .catch((err) => {
          setErrorMessage("An error occurred. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (timeRemaining <= 0 && !isInvoiceExpired) {
      setInvoiceExpiredApiCall();
      setIsInvoiceExpired(true);
    }
  }, [timeRemaining, isInvoiceExpired, invoice]);

  return (
    <FillWithMargin>
      <div>
        <h1>Pay Invoice</h1>
        {errorMessage !== "" ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
        {invoice !== null ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {fetching ? (
                <CenterSpinner />
              ) : (
                <MDBCard alignment="center" style={{ margin: "auto" }}>
                  <MDBCardHeader>Invoice from {state.gig.postedBy}</MDBCardHeader>
                  <MDBCardBody>
                    {timeRemaining <= 0 ? (
                      <MDBCardText>
                        <QRCode value={invoice.invoice} />
                        <MDBListGroup style={{ marginTop: "1rem" }}>
                          <MDBListGroupItem>
                            Invoice expired. Please request a new invoice.
                          </MDBListGroupItem>
                        </MDBListGroup>
                      </MDBCardText>
                    ) : (
                      <>
                        <MDBCardTitle>
                          <FormattedNumber number={invoice.amount} /> sats
                        </MDBCardTitle>
                        <MDBCardText>
                          <QRCode value={invoice.invoice} />
                          <MDBListGroup style={{ marginTop: "1rem" }}>
                            <MDBListGroupItem>
                              <strong>Time Remaining:</strong> {formatTime(timeRemaining)}
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                              <strong>Memo:</strong> {memo}
                            </MDBListGroupItem>
                          </MDBListGroup>
                        </MDBCardText>
                      </>
                    )}
                  </MDBCardBody>
                  {timeRemaining <= 0 ? (
                    <>
                      <MDBCardFooter>
                        <Button text={"Request new invoice"} onClick={fetchNewInvoice}></Button>
                      </MDBCardFooter>
                    </>
                  ) : (
                    <>
                      <MDBCardFooter>
                        <Button text={"Copy invoice to clipboard"}></Button>
                      </MDBCardFooter>
                    </>
                  )}
                </MDBCard>
              )}

              <p style={{ marginTop: "1rem" }}>
                Once you have paid the invoice, click Continue below.
              </p>
              <div>
                <Button
                  text={"Continue"}
                  onClick={setInvoicePaid}
                  enabled={!loading && !fetching}
                ></Button>
              </div>
            </div>
          </>
        ) : (
          <CenterSpinner />
        )}
      </div>
    </FillWithMargin>
  );
}

export default PayInvoice;

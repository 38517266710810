import React from "react";
import { Link } from "react-router-dom";

import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import FormattedNumber from "../Components/FormattedNumber";
import BoostIcon from "../Components/BoostIcon";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import { useNavigate } from "react-router-dom";

export default function GigCard({ job }) {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  return (
    <>
      <MDBCard key={job.id} className="h-100">
        <MDBCardImage
          src={job.image}
          alt="..."
          position="top"
          style={{ height: "30vh", objectFit: "cover" }}
          onClick={() => {
            navigate(`/gig/${job.id}`);
          }}
        />
        <MDBCardBody>
          <MDBRow>
            <MDBCol size="auto">
              <MDBCardImage
                src={job.sellerProfilePic}
                style={{
                  height: "2rem",
                  width: "2rem",
                  borderRadius: "1rem",
                  margin: 0,
                  objectFit: "cover", // add this line
                }}
              />
            </MDBCol>

            <MDBCol size="auto" style={{ padding: 0 }}>
              <Link to={`/user/${job.postedBy}`} style={{ color: "black" }}>
                {job.postedBy}
              </Link>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <Link
              to={`/gig/${job.id}`}
              style={{
                fontSize: "1.25rem",
                lineHeight: 1.2,
                marginTop: "1rem",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              {job.title}
            </Link>
          </MDBRow>
          <MDBRow>
            {job.sellerRating > 0 ? (
              <MDBCol size="auto">
                {[...Array(job.sellerRating)].map((e, i) => {
                  return <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />;
                })}
              </MDBCol>
            ) : (
              <></>
            )}
            <MDBCol style={job.sellerRating > 0 ? { padding: 0 } : {}}>
              <MDBCardText>
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {job.sellerRating} stars
                </span>
                <span>
                  <BoostIcon gigId={job.id} />
                </span>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardFooter style={{ textAlign: "right" }}>
          <Link href={job.link} style={{ textAlign: "right", color: "black" }}>
            <span
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              STARTING AT <FormattedNumber number={job.pay} />
            </span>
          </Link>
        </MDBCardFooter>
      </MDBCard>
    </>
  );
}

import React from "react";
import { MDBCardTitle, MDBCardText, MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const CustomCard = ({ title, cardText, linkHref }) => {
  return (
    <>
      <MDBCardTitle
        style={{
          fontSize: "1.25rem",
          lineHeight: 1.2,
          color: "black",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        ⚡{title}
      </MDBCardTitle>
      <MDBCardText style={{ textAlign: "left" }}>{cardText}</MDBCardText>
      <MDBBtn
        style={{
          width: "100%",
          backgroundColor: "orange",
        }}
      >
        <Link
          to={linkHref}
          style={{
            // fontSize: "1.25rem",
            color: "white",
            lineHeight: 1.2,
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          Continue
        </Link>
      </MDBBtn>
    </>
  );
};

export default CustomCard;

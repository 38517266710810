import { MDBBtn } from "mdb-react-ui-kit";
import React, { useState } from "react";

const ScrollingButtons = ({ buttons, onSelect, onDeSelect }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);

  function isButtonSelected(index) {
    return selectedButtons.filter((button) => button === index).length > 0;
  }

  const handleButtonClick = (value, index) => {
    if (isButtonSelected(index)) {
      if (value.id === -1) {
        setSelectedButtons([]);
        buttons.forEach((button) => onDeSelect(button));
      } else {
        // remove this index from the list of selected buttons
        setSelectedButtons((curr) => curr.filter((button) => button !== index));
        onDeSelect(value); // Call the callback function with null when deselected
      }
    } else {
      if (value.id === -1) {
        setSelectedButtons(buttons.map((button, index) => index));
        buttons.forEach((button) => onSelect(button));
      } else {
        // add index to list of selected buttons
        setSelectedButtons((curr) => [...curr, index]);
        onSelect(value); // Call the callback function with the selected value
      }
    }
  };

  return (
    <div>
      {buttons.map((button, index) => (
        <MDBBtn
          key={index}
          style={{
            padding: "10px",
            margin: "5px",
            backgroundColor:
              selectedButtons.filter((button) => button === index).length > 0 ? "orange" : "white",
            color: "gray",
          }}
          onClick={() => handleButtonClick(button, index)}
        >
          {button.category + " (" + button.count + ")"}
        </MDBBtn>
      ))}
    </div>
  );
};

export default ScrollingButtons;

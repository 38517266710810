import React, { useState } from "react";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import CustomCard from "./CustomCard";

export default function Packages({ job, jobId }) {
  const isDesktop = useIsDesktop();
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <MDBCard
        className="text-center"
        style={{
          position: isDesktop ? "sticky" : "",
          top: isDesktop ? "2rem" : "",
        }}
      >
        <MDBCardHeader>
          <MDBTabs pills className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
                style={{
                  backgroundColor: basicActive === "tab1" ? "orange" : "",
                }}
              >
                Basic
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
                style={{
                  backgroundColor: basicActive === "tab2" ? "orange" : "",
                }}
              >
                Standard
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
                style={{
                  backgroundColor: basicActive === "tab3" ? "orange" : "",
                }}
              >
                Premium
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"}>
              <CustomCard
                title={job.basicPrice.toLocaleString()}
                cardText={job.basicDescription}
                linkHref={`/cart/${jobId}/basic`}
              />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"}>
              <CustomCard
                title={job.standardPrice.toLocaleString()}
                cardText={job.standardDescription}
                linkHref={`/cart/${jobId}/standard`}
              />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab3"}>
              <CustomCard
                title={job.premiumPrice.toLocaleString()}
                cardText={job.premiumDescription}
                linkHref={`/cart/${jobId}/premium`}
              />
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCardBody>
      </MDBCard>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardFooter,
} from "mdb-react-ui-kit";

export default function SellerInformationCard({ profile }) {
  const [memberSince, setMemberSince] = useState("");

  useEffect(() => {
    if (profile.memberSince !== "") {
      const date = new Date(profile.memberSince);
      const options = { month: "long", day: "numeric", year: "numeric" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );
      setMemberSince(formattedDate);
    }
  }, [profile.memberSince]);

  return (
    <>
      <MDBCard alignment="left">
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <MDBCardText style={{ marginBottom: "0" }}>From</MDBCardText>
              <MDBCardText style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                {profile.from}
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText style={{ marginBottom: "0" }}>
                Member Since
              </MDBCardText>
              <MDBCardText style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                {memberSince}
              </MDBCardText>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCardText style={{ marginBottom: "0" }}>
                Avg. Response Time
              </MDBCardText>
              <MDBCardText style={{ fontWeight: "bold" }}>
                {profile.avgResponseTime} hour
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText style={{ marginBottom: "0" }}>
                Last Delivery
              </MDBCardText>
              <MDBCardText style={{ fontWeight: "bold" }}>
                {profile.lastDelivery} days
              </MDBCardText>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardFooter className="text-muted">
          {profile.sellerStatement}
        </MDBCardFooter>
      </MDBCard>
    </>
  );
}

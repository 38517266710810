const { MDBRow, MDBCol, MDBProgress, MDBProgressBar } = require("mdb-react-ui-kit");

function ProgressBar({ progress, label }) {
  return (
    <MDBRow>
      <MDBCol size="2">
        <p
          style={{
            fontWeight: "bold",
            marginBottom: 0,
            textAlign: "center",
          }}
        >
          {label}
        </p>
      </MDBCol>
      <MDBCol style={{ marginTop: "auto", marginBottom: "auto" }}>
        <MDBProgress height="6">
          <MDBProgressBar
            width={progress}
            valuemin={0}
            valuemax={100}
            style={{ backgroundColor: "orange" }}
          />
        </MDBProgress>
      </MDBCol>
    </MDBRow>
  );
}

export default ProgressBar;

import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function AddonsGrid({ initialData, onStateChange }) {
  const [formFields, setFormFields] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setFormFields(initialData);
    }
  }, [initialData]);

  //[ { addon: "", additionalCost: "", additionalTime: "" }, ]

  const [centredModal, setCentredModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [removalIndex, setRemovalIndex] = useState(null);

  const toggleShow = () => setCentredModal(!centredModal);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
    onStateChange(data);
  };

  const addFields = () => {
    let object = {
      title: "",
      additionalCost: 1000,
      additionalTime: 1,
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  return (
    <>
      <div className="mb-4">
        <MDBTable bordered style={{ marginBottom: "0px" }}>
          <MDBTableHead>
            <tr>
              <th scope="col">Addon</th>
              <th scope="col">Additional Cost</th>
              <th scope="col">Additional Time</th>
              <th scope="col"></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {formFields.map((form, index) => {
              return (
                <tr key={index}>
                  <td>
                    <input
                      onChange={(event) => handleFormChange(event, index)}
                      style={inputStyle}
                      value={form.title}
                      name="title"
                      placeholder="Addon title"
                    ></input>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        style={numberInputStyle}
                        label="Number input"
                        id="typeNumber"
                        type="number"
                        placeholder="3"
                        name="additionalCost"
                        value={form.additionalCost}
                        onChange={(event) => handleFormChange(event, index)}
                      />
                      <p style={{ marginTop: "auto", marginBottom: "auto" }}>Sats</p>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        style={numberInputStyle}
                        label="Number input"
                        id="typeNumber"
                        type="number"
                        placeholder="3"
                        name="additionalTime"
                        value={form.additionalTime}
                        onChange={(event) => handleFormChange(event, index)}
                      />
                      <p style={{ marginTop: "auto", marginBottom: "auto" }}>Days</p>
                    </div>
                  </td>
                  <td>
                    {/* <Checkbox /> */}
                    <MDBIcon
                      far
                      icon="minus-square"
                      style={{ color: "orange" }}
                      onClick={() => {
                        setRemovalIndex(index);
                        setCentredModal(!centredModal);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </MDBTableBody>
        </MDBTable>
      </div>

      <div className="mb-4">
        <MDBCol style={{ paddingLeft: "0px" }} size="auto">
          <MDBBtn style={{ backgroundColor: "orange" }} onClick={addFields}>
            {/* <MDBIcon far icon="plus-square" /> */}
            Add addon
          </MDBBtn>
        </MDBCol>
      </div>

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete Addon</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you sure you want to delete this addon?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={toggleShow}>
                Close
              </MDBBtn>
              <MDBBtn
                onClick={() => {
                  removeFields(removalIndex);
                  toggleShow();
                }}
                style={{ backgroundColor: "orange" }}
              >
                Confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal tabIndex="-1" show={saveModal} setShow={setSaveModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete addon</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setSaveModal(!saveModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you sure you want to delete this addon?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                onClick={() => {
                  setSaveModal(!saveModal);
                }}
                style={{ backgroundColor: "orange" }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

const inputStyle = {
  border: "none",
  backgroundColor: "transparent",
  resize: "none",
  outline: "none",
  width: "100%",
};

const numberInputStyle = {
  ...inputStyle,
  textAlign: "right",
};

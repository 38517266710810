import React from "react";

import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import LinkButton from "../Components/LinkButton";

export default function PackageTable({ job, jobId, gigPackage }) {
  return (
    <>
      <MDBTable hover style={{ marginTop: "1rem" }}>
        <MDBTableHead>
          <tr style={{ verticalAlign: "top", textAlign: "start" }}>
            <th scope="col" colSpan={2}>
              <p style={{ fontSize: "1.2rem" }}>⚡{job[gigPackage + "Price"].toLocaleString()}</p>
              <h3 style={{ fontWeight: "bold" }}>
                {gigPackage.charAt(0).toUpperCase() + gigPackage.slice(1)}
              </h3>
              <p>{job[gigPackage + "Description"]}</p>
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <th scope="row" style={{ fontWeight: "bold", width: "50%" }}>
              Delivery Time
            </th>
            <td>{job[gigPackage + "DeliveryTime"]} days</td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: "bold", width: "50%" }}>
              Revisions
            </th>
            <td>{job[gigPackage + "Revisions"]}</td>
          </tr>
          {job.features.map((feature, index) => {
            return (
              <tr key={index}>
                <th scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                  {feature.title}
                </th>
                <td>{feature[gigPackage + "Description"]}</td>
              </tr>
            );
          })}
        </MDBTableBody>
      </MDBTable>
      <div style={{ paddingTop: "1rem" }}>
        <LinkButton
          to={`/cart/${jobId}/basic`}
        >{`CONTINUE WITH ${gigPackage.toUpperCase()}`}</LinkButton>
      </div>
    </>
  );
}

// import React from "react";
// import { MDBBtn } from "mdb-react-ui-kit";
// import Button from "../Components/Button";
// import pexels_2 from "../images/pexels_2.jpeg";

// export default function CallToAction() {
//   return (
//     <>
//       <div
//         className="p-5 text-center bg-image"
//         style={{
//           backgroundImage: `url(${pexels_2})`,
//           height: "400px",
//         }}
//       >
//         <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
//           <div className="d-flex justify-content-center align-items-center h-100">
//             <div className="text-white">
//               <h1 style={{ fontWeight: "bold", margin: "2rem" }}>
//                 Find the talent needed to get your business growing
//               </h1>
//               <Button text={"Get Started"} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import pexels_2 from "../images/pexels_2.jpeg";
import LinkButton from "../Components/LinkButton";

export default function CallToAction() {
  return (
    <>
      <div className="bg-image" style={{ position: "relative" }}>
        <img
          src={pexels_2}
          alt=""
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
        <div
          className="mask"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></div>
        <div className="container" style={{ color: "white" }}>
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center" style={{ padding: "2rem" }}>
              <h1 className="mb-4" style={{ fontWeight: "bold" }}>
                Find the talent needed to get your business growing
              </h1>
              <p className="lead mb-5">
                Looking to hire top-notch freelancers for your next project? Browse our network of
                talented professionals to find the right match for your needs.
              </p>
              <LinkButton
                to="/explore"
                color="primary"
                size="lg"
                style={{ backgroundColor: "orange" }}
              >
                GET STARTED
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

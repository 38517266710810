import React, { useState, useEffect } from "react";
import axios from "axios";
import FillWithMargin from "../Components/FillWithMargin";
import { MDBTextArea } from "mdb-react-ui-kit";
import Button from "../Components/Button";
import { getApiDomain } from "../App";
import { useParams, useNavigate } from "react-router-dom";

function Revision() {
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [waiting, setWaiting] = useState(false); // waiting for response from server
  const { orderId } = useParams();

  const navigate = useNavigate();

  async function submit() {
    try {
      setWaiting(true);
      const response = await axios.post(getApiDomain() + "/orders/revision", {
        description: description,
        orderId: orderId,
      });
      setErrorMessage("");
      if (response.data.error !== undefined) {
        setErrorMessage(response.data.error);
      } else {
        navigate("/orders");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    } finally {
      setWaiting(false);
    }
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Revise Your Order</h1>
      <p>
        Please enter instructions for the seller to know exactly what differences you would like
        made to your order.
      </p>
      {errorMessage !== "" && <p style={{ color: "red" }}>{errorMessage}</p>}
      <MDBTextArea
        label="Revision details"
        id="textAreaExample"
        rows={4}
        value={description}
        onChange={(val) => setDescription(val.target.value)}
        maxLength={2000}
      />
      <div style={{ marginTop: "0.5rem", color: "gray" }}>
        Character count: {description.length}. Minimum Characters: 150
      </div>
      <Button
        style={{ marginTop: "1rem" }}
        text={"SUBMIT"}
        enabled={description.length >= 150 && !waiting}
        onClick={submit}
      ></Button>
    </FillWithMargin>
  );
}

export default Revision;

import FillWithMargin from "../Components/FillWithMargin";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import axios from "axios";
import LinkButton from "../Components/LinkButton";
import RevisionCard from "./RevisionCard";

function Revisions() {
  const { orderId } = useParams();
  const [revisions, setRevisions] = useState([]);
  const [revisionsLoading, setRevisionsLoading] = useState(true);

  useEffect(() => {
    async function getRevisions() {
      try {
        const response = await axios.get(getApiDomain() + "/getRevisions", {
          params: { orderId: orderId },
        });
        setRevisions(response.data.revisions);
        setRevisionsLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      }
    }
    getRevisions();
  }, [orderId]);

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Revisions</h1>
      <p>Here are the revisions for order {orderId}.</p>
      {revisionsLoading && <CenterSpinner />}
      {!revisionsLoading && revisions.length === 0 && (
        <p>You have not submitted any revisions for this order.</p>
      )}
      {!revisionsLoading && revisions.length > 0 && (
        <>
          {revisions.map((revision, index) => (
            <RevisionCard key={index} revision={revision}>
              {revision.showCompleteButton && revision.status === "Incomplete" && (
                <LinkButton to={`/deliver/${orderId}`}>COMPLETE REVISION</LinkButton>
              )}
            </RevisionCard>
          ))}
        </>
      )}
    </FillWithMargin>
  );
}

export default Revisions;

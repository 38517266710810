import React, { useState, useEffect } from "react";
import { MDBCheckbox } from "mdb-react-ui-kit";

const Checkbox = ({ onChange, checked, label }) => {
  const [checkedState, setCheckedState] = useState(checked);
  if (label) {
    return (
      <div style={{ display: "flex" }}>
        <CheckboxWithoutLabel onChange={onChange} defaultChecked={checkedState} />
        <p
          style={{ cursor: "pointer" }}
          onClick={(val) => {
            if (onChange) {
              onChange(!checkedState);
            }
            setCheckedState((curr) => !curr);
          }}
        >
          {label}
        </p>
      </div>
    );
  }

  return <CheckboxWithoutLabel onChange={onChange} defaultChecked={checked} />;
};

export default Checkbox;

function CheckboxWithoutLabel({ onChange, defaultChecked = false }) {
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  // Update the checked state when the defaultChecked prop changes
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <MDBCheckbox
      name="checkNoLabel"
      id="checkboxNoLabel"
      value=""
      style={{
        borderColor: "orange",
        backgroundColor: checked ? "orange" : "",
      }}
      onChange={handleChange}
      aria-label="..."
      checked={checked}
    />
  );
}
